import { FormGroup } from '@angular/forms';
import { AvailableOptions, BookingType, PrintDto } from '@burddy-monorepo/shared/shared-data';

export type SetOptionsPayload = {
  option: AvailableOptions;
  quantity: number;
};
export class SetBookingType {
  public static readonly type = '[Booking] SetBookingType';
  constructor(public data: BookingType) {}
}

export class SetBookingFormGroup {
  public static readonly type = '[Booking] SetBookingFormGroup';
  constructor(public data: FormGroup) {}
}

export class AddReturnAddressFormGroup {
  public static readonly type = '[Booking] AddReturnAddressFormGroup';
  constructor() {}
}

export class RemoveReturnAddressFormGroup {
  public static readonly type = '[Booking] RemoveReturnAddressFormGroup';
  constructor() {}
}

export class SetBookingStep {
  public static readonly type = '[Booking] SetBookingStep';
  constructor(public data: number) {}
}

export class VerifyAddress {
  public static readonly type = '[Booking] VerifyAddress';
  constructor() {}
}

export class GoNextStep {
  public static readonly type = '[Booking] GoNextStep';
  constructor() {}
}

export class ClickOnPrimaryButton {
  public static readonly type = '[Booking] ClickOnPrimaryButton';
  constructor() {}
}

export class InitBooking {
  public static readonly type = '[Booking] InitBooking';
  constructor(public bookingType?: BookingType) {}
}

export class VerifyDate {
  public static readonly type = '[Booking] VerifyDate';
  constructor() {}
}

export class SetOptions {
  public static readonly type = '[Booking] SetOptions';
  constructor(public data: SetOptionsPayload[]) {}
}

export class SetPrints {
  public static readonly type = '[Booking] SetPrints';
  constructor(public selectedPrint: PrintDto) {}
}

export class SetIsLoading {
  public static readonly type = '[Booking] SetIsLoading';
  constructor(public isLoading: boolean) {}
}

export class SetPossibleDeliveryDates {
  public static readonly type = '[Booking] SetPossibleDeliveryDates';
  constructor(public dates: string[]) {}
}

export class SetPossibleReturnDates {
  public static readonly type = '[Booking] SetPossibleReturnDates';
  constructor(public dates: string[]) {}
}

export class SetDuration {
  public static readonly type = '[Booking] SetDuration';
  constructor(public duration: number) {}
}

export class SetMandatoryOptions {
  public static readonly type = '[Booking] SetMandatoryOptions';
}

export class ClearData {
  public static readonly type = '[Booking] ClearData';
}

export class PrepareBookingForm {
  public static readonly type = '[Booking] PrepareBookingForm';
  constructor(public bookingType?: BookingType) {}
}
export class CheckPromoCode {
  public static readonly type = '[Booking] CheckPromoCode';
  constructor(public code: string) {}
}

export class GetIsActive {
  public static readonly type = '[Booking] GetIsActive';
}
