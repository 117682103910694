import { OrderPaymentMethod, OrderStatus } from '../enums';
import { AddressData } from './address-data.interface';
import { BookingConfigData } from './booking-config-data.interface';
import { PrintDto } from './dto';

export interface BookingData {
  id?: string;
  deliveryAddress?: AddressData;
  returnAddress?: AddressData;
  options?: Map<string, number>;
  selectedEventDate?: Date;
  duration?: number;
  infoForUser?: InfoForUser;
  selectedPrints?: PrintDto;
  totalAmount?: number;
  status?: OrderStatus;
  paymentMethod?: OrderPaymentMethod;
  configs?: BookingConfigData[];
  canBeAllEdited?: boolean;
  canEditDeliveryAddress?: boolean;
  canEditReturnAddress?: boolean;
}

export interface InfoForUser {
  deliveryDate?: Date;
  returnDate?: Date;
}

export type BookingType = 'b2b' | 'b2c';
