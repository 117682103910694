<ng-container *ngIf="data">
  <div class="step" *ngFor="let step of iterateArray">
    <div
      class="rectangle"
      [ngClass]="{
        primary: step === data.currentStep,
        accent: step !== data.currentStep,
        passed: step < data.currentStep
      }"
    ></div>
    <div
      class="stepNumber"
      [ngClass]="{
        primary: step === data.currentStep,
        accent: step !== data.currentStep,
        passed: step < data.currentStep
      }"
    >
      <ng-container *ngIf="step < data.currentStep; else stepNumber">
        <img src="./assets/img/icons/check.svg" />
      </ng-container>
      <ng-template #stepNumber>
        <span>{{ step }}</span>
      </ng-template>
    </div>
  </div>
</ng-container>
