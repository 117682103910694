import { FormControl, FormGroup } from '@angular/forms';
import { getValidatorsBasedOnRules } from '@burddy-monorepo/front/shared/validators';
import { getBookingCustomizationRules } from '@burddy-monorepo/shared/shared-data';

export interface BookingCustomizationFormGroupInitValues {
  eventName: string;
  numberOfPrints: number;
  wantSendEmail: boolean;
  mailObject: string;
  mailContent: string;
}

export class BookingCustomizationFormGroup extends FormGroup {
  constructor(init?: BookingCustomizationFormGroupInitValues) {
    const rules = getBookingCustomizationRules();
    super({
      [rules.eventName.fieldName]: new FormControl(
        init?.eventName,
        getValidatorsBasedOnRules(rules.eventName)
      ),
      [rules.numberOfPrints.fieldName]: new FormControl(
        init?.numberOfPrints,
        getValidatorsBasedOnRules(rules.numberOfPrints)
      ),
      [rules.mailObject.fieldName]: new FormControl(
        init?.mailObject,
        getValidatorsBasedOnRules(rules.mailObject)
      ),
      [rules.wantToSendMail.fieldName]: new FormControl(
        init?.wantSendEmail,
        getValidatorsBasedOnRules(rules.wantToSendMail)
      ),
      [rules.mailContent.fieldName]: new FormControl(
        init?.mailContent,
        getValidatorsBasedOnRules(rules.mailContent)
      ),
    });
  }
}
