export interface BookingConfigData {
  id?: number;
  eventName?: string;
  title?: string;
  mailObject?: string;
  mailContent?: string;
  personalBackgroundUrl?: string;
  isPersonalBackground?: boolean;
  isOriginalConfig?: boolean;
  configVersion?: string;
  frameToolDeleteUrl?: string;
  frameToolId?: string;
  frameToolDemoImageUrl?: string;
  numberOfPrints?: number;
  wantEmail?: boolean;
}
