<burddy-monorepo-card>
  <div class="booking_card_content">
    <div class="main_info">
      <!-- BADGE SATUS SECTION -->
      <burddy-monorepo-badge
        *ngIf="!isPassedOrder"
        class="very-small"
        [ngClass]="classOfStatus"
      >
        <img
          src="/assets/img/icons/calendar_{{ classOfStatus }}.svg"
          alt="calendar"
        />
        {{
          'COMPONENTS.BOOKING_CARD.ORDER_STATUS.' + booking.status | translate
        }}
      </burddy-monorepo-badge>
      <burddy-monorepo-badge *ngIf="isPassedOrder" class="very-small error">
        <img src="/assets/img/icons/calendar_error.svg" alt="calendar" />
        {{ 'COMPONENTS.BOOKING_CARD.ORDER_STATUS.PASSED' | translate }}
      </burddy-monorepo-badge>

      <!-- DATE TITLE SECTION -->
      <h3>{{ dateTitle }}</h3>
      <h4>{{ eventName }}</h4>

      <!-- BUTTON SECTION -->
      <button
        (click)="editBooking()"
        *ngIf="booking.canBeAllEdited"
        class="primary small"
      >
        {{ 'COMPONENTS.BOOKING_CARD.EDIT_BOOKING_BUTTON' | translate }}
      </button>

      <hr />

      <!-- PRICE INFO SECTION -->
      <p class="bold">
        <small>
          {{ 'COMPONENTS.BOOKING_CARD.TOTAL' | translate }} :
          {{ booking.totalAmount }} €
        </small>
      </p>
      <p>
        <small>
          {{ 'COMPONENTS.BOOKING_CARD.DURATION' | translate }} :
          {{ booking.duration }}</small
        >
      </p>
      <p>
        <small>
          {{ 'COMPONENTS.BOOKING_CARD.OPTIONS' | translate }} :
          {{ optionsQuantity }}</small
        >
      </p>
      <p *ngIf="printValue">
        <small>
          {{ 'COMPONENTS.BOOKING_CARD.PRINTS' | translate }} :
          {{ printValue }}</small
        >
      </p>
    </div>
    <div class="footer -column">
      <burddy-monorepo-button
        (click)="downloadInvoice()"
        [class]="'large tertiary'"
      >
        {{ 'COMPONENTS.BOOKING_CARD.DOWNLOAD_INVOICE_BUTTON' | translate }}
      </burddy-monorepo-button>
      <burddy-monorepo-button
        *ngIf="!isPassedOrder"
        (click)="editConfig()"
        [class]="'large primary'"
      >
        {{ 'COMPONENTS.BOOKING_CARD.EDIT_CONFIG_BUTTON' | translate }}
      </burddy-monorepo-button>
    </div>
  </div>
</burddy-monorepo-card>
