import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  inject,
  Injector,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { CardComponent } from '@burddy-monorepo/front/shared/components';
import { ClearData, PrepareBookingForm } from '@burddy-monorepo/front/shared/data';
import { BadgeComponent, ButtonComponent, HeaderComponent, ModalService } from '@burddy-monorepo/front/shared/ui-kit';
import { TranslateModule } from '@ngx-translate/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';

import { BookingState } from '../../data/states';
import { MainRoutes } from '../../routes/main/main-routes';
import { BookingHeaderData } from './booking-header-data.interface';

@Component({
  selector: 'burddy-monorepo-booking-header',
  standalone: true,
  imports: [
    HeaderComponent,
    ButtonComponent,
    NgIf,
    TranslateModule,
    BadgeComponent,
    CardComponent,
  ],
  templateUrl: './booking-header.component.html',
  styleUrls: ['./booking-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingHeaderComponent {
  @Input() data!: BookingHeaderData;

  @ViewChild('leavingAlert') leavingAlert!: TemplateRef<any>;
  public injector = inject(Injector);

  private _leavingAlertModal: ComponentRef<any> | undefined;
  private _store = inject(Store);
  private _modalService = inject(ModalService);
  askQuitBooking() {
    if (this._store.selectSnapshot(BookingState.bookingForm)?.pristine) {
      this.quitBooking();
    } else {
      if (this.leavingAlert && !this._leavingAlertModal) {
        this._leavingAlertModal = this._modalService.openModal({
          template: this.leavingAlert,
          options: {
            size: 'md',
            showCloseButton: false,
            injector: this.injector,
          },
        }) as ComponentRef<any>;
      }
    }
  }
  quitBooking() {
    this._store.dispatch([
      new ClearData(),
      new Navigate([MainRoutes.BOOKING]),
      new PrepareBookingForm(),
    ]);
    this.leaveAlert();
  }
  leaveAlert() {
    if (this._leavingAlertModal) {
      this._modalService.closeModal(this._leavingAlertModal);
      this._leavingAlertModal = undefined;
    }
  }
}
