import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AvailableOptions, PRICES } from '@burddy-monorepo/shared/shared-data';
import { TranslateModule } from '@ngx-translate/core';

import { SelectOptionBlocComponent } from '../../containers';

@Component({
  selector: 'burddy-monorepo-select-options-control',
  standalone: true,
  imports: [NgFor, SelectOptionBlocComponent, NgIf, TranslateModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SelectOptionsControlComponent,
      multi: true,
    },
  ],
  templateUrl: './select-options-control.component.html',
  styleUrls: ['./select-options-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectOptionsControlComponent implements ControlValueAccessor {
  @Input() listOfOptions!: AvailableOptions[];
  @Output() selectedOptionsChange = new EventEmitter<AvailableOptions[]>();

  public PRICES = PRICES;
  public selectedOptions: AvailableOptions[] = [];
  public onChange!: (...arg: unknown[]) => unknown;
  public onTouched!: (...arg: unknown[]) => unknown;

  public writeValue(obj: AvailableOptions[]): void {
    this.selectedOptions = obj ?? [];
  }
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public changeValue() {
    this.onChange(this.selectedOptions);
    this.onTouched();
    this.selectedOptionsChange.emit(this.selectedOptions);
  }

  clickBlock(optionToSelect: AvailableOptions) {
    const foundIndex = this.selectedOptions.indexOf(optionToSelect);
    if (foundIndex >= 0) {
      this.selectedOptions.splice(foundIndex, 1);
    } else {
      this.selectedOptions.push(optionToSelect);
    }
    this.changeValue();
  }
}
