import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PHONE_ALLOWED_COUNTRIES } from '@burddy-monorepo/shared/shared-data';
import * as intlTelinput from 'intl-tel-input';

@Component({
  selector: 'burddy-monorepo-input-tel',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './input-tel.component.html',
  styleUrls: ['./input-tel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: InputTelComponent, multi: true },
  ],
})
export class InputTelComponent implements AfterViewInit, ControlValueAccessor {
  @ViewChild('inputPhone') inputPhone!: ElementRef;
  @Input() class: string | undefined;
  public onChange!: (...arg: unknown[]) => unknown;
  public onTouched!: (...arg: unknown[]) => unknown;

  public isDisabled!: boolean;
  private _intlTelInput!: intlTelinput.Plugin;
  private _initialValue = '';

  ngAfterViewInit(): void {
    this._intlTelInput = intlTelinput(this.inputPhone.nativeElement, {
      onlyCountries: PHONE_ALLOWED_COUNTRIES,
      separateDialCode: true,
      formatOnDisplay: true,
      initialCountry: 'fr',

      utilsScript:
        'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/18.2.1/js/utils.min.js', // TODO : chercher une meilleure solution qu'importer un script depuis un CDN
    });
    this.inputPhone.nativeElement.addEventListener('countrychange', () => {
      this.changeValue();
    });
    if (this._initialValue) {
      this._intlTelInput.setNumber(this._initialValue);
    }
  }
  public changeValue() {
    this.onChange(this._intlTelInput.getNumber());
    this.onTouched();
  }

  writeValue(obj: string): void {
    this._initialValue = obj;
    if (this.inputPhone) {
      this.inputPhone.nativeElement.value = obj;
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
