import { AbstractControl, ValidatorFn } from '@angular/forms';
import { EMAIL, PHONE } from '@burddy-monorepo/shared/shared-data';

export const uniqueContactValidator = (controlNames: string[]): ValidatorFn => {
  return (group: AbstractControl): { [key: string]: boolean } | null => {
    const contactForms =
      controlNames.map((controlName) => group.get(controlName)?.value) || [];

    const emails: string[] = [];
    const phones: string[] = [];

    contactForms.forEach((contactForm) => {
      if (contactForm[EMAIL.FIELD_NAME]) {
        emails.push(contactForm[EMAIL.FIELD_NAME]);
      }
      if (contactForm[PHONE.FIELD_NAME]) {
        phones.push(contactForm[PHONE.FIELD_NAME]);
      }
    });

    if (
      [...new Set(emails)].length < emails.length ||
      [...new Set(phones)].length < phones.length
    ) {
      return { UNIQUE_CONTACT: true };
    }
    return null;
  };
};
