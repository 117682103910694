import { AddressFormGroup } from '@burddy-monorepo/front/shared/components';
import {
  AddressData,
  getAddressRules,
} from '@burddy-monorepo/shared/shared-data';

export function addressFormGroupToAddressData(
  source: AddressFormGroup
): AddressData {
  const rules = getAddressRules();
  return {
    city: source?.get(rules.city.fieldName)?.value,
    streetName: source?.get(rules.streetName.fieldName)?.value,
    streetNumber: source?.get(rules.streetNumber.fieldName)?.value,
    postalCode: source?.get(rules.postalCode.fieldName)?.value,
    country: source?.get(rules.country.fieldName)?.value,
    complementInfo: source?.get(rules.complement.fieldName)?.value,
  };
}
