<div class="main-block" [ngClass]="{ selected: isSelected }">
  <div
    class="image-block"
    *ngIf="imagePath"
    [style.background]="
      'url(' + imagePath + '),lightgray 50% / cover no-repeat;'
    "
  ></div>
  <div class="text-block">
    <h3 *ngIf="title" [innerHTML]="title ?? '' | translate"></h3>
    <p *ngIf="description" [innerHTML]="description ?? '' | translate"></p>
    <burddy-monorepo-badge
      *ngIf="price !== undefined && price !== null"
      class="accent small"
    >
      <span class="bold">{{ price }} €</span>
    </burddy-monorepo-badge>
  </div>
</div>
