import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { BadgeComponent } from '../badge/badge.component';

@Component({
  selector: 'burddy-monorepo-select-option-bloc',
  standalone: true,
  imports: [TranslateModule, NgIf, BadgeComponent, NgClass],
  templateUrl: './select-option-bloc.component.html',
  styleUrls: ['./select-option-bloc.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectOptionBlocComponent {
  @Input() imagePath?: string;
  @Input() title?: string;
  @Input() description?: string;
  @Input() price?: number;
  @Input() isSelected?: boolean;
  @Output() isSelectedChange = new EventEmitter<boolean>();
}
