import { NgClass, NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DoCheck,
  Input,
} from '@angular/core';

import { StepperComponentData } from './stepper-component-data.interface';

@Component({
  selector: 'burddy-monorepo-stepper',
  standalone: true,
  imports: [NgFor, NgClass, NgIf],
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepperComponent implements DoCheck {
  @Input() data!: StepperComponentData;
  iterateArray: number[] = [];

  ngDoCheck(): void {
    if (this.data.stepNumber) {
      this.iterateArray = Array(this.data.stepNumber)
        .fill(0)
        .map((x, i) => i + 1);
    } else {
      this.iterateArray = [];
    }
  }
}
