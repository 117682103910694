import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { ENVIRONNEMENT, IEnvironment } from '@burddy-monorepo/front/shared/interfaces';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor(
    private cookieService: CookieService,
    @Optional() @Inject(ENVIRONNEMENT) private environment: IEnvironment
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.url.includes(this.environment.bookingAPI_URL)) {
      const jwt = this.cookieService.get('customer_jwt');
      if (jwt) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${jwt}`,
          },
        });
      }
    }
    return next.handle(request);
  }
}
