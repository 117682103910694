<h5 *ngIf="title" [innerHTML]="title"></h5>
<ng-container *ngIf="data$ | async as data">
  <ng-container [formGroup]="formGroup">
    <ng-container *ngIf="formGroup">
      <div class="-form-line">
        <div class="-form-item">
          <label for="login">{{ 'FORM.LOGIN.LOGIN' | translate }}</label>
          <input
            name="login"
            [maxlength]="+(loginRules.login.maxLength ?? 99999)"
            [minlength]="+(loginRules.login.minLength ?? 0)"
            [formControlName]="loginRules.login.fieldName"
            type="email"
            [placeholder]="'Ex: Sébastien@gmail.com'"
          />
        </div>
      </div>

      <div class="-form-line">
        <div class="-form-item">
          <label>{{ 'FORM.LOGIN.PASSWORD' | translate }}</label>
          <input
            type="password"
            [formControlName]="loginRules.password.fieldName"
          />
        </div>
      </div>

      <a class="clickable" (click)="goToPasswordRecovery()">{{
        'FORM.LOGIN.PASSWORD_FORGOTTEN' | translate
      }}</a>
    </ng-container>

    <div class="-row -start">
      <burddy-monorepo-button
        [disabled]="!formGroup.valid || data[0]"
        class="primary medium"
        (click)="connect()"
      >
        <span *ngIf="!data[0]">
          {{ 'FORM.LOGIN.CONNECT_BUTTON' | translate }}
        </span>
        <span *ngIf="data[0]">
          {{ 'FORM.LOGIN.CONNECTING_BUTTON' | translate }}
          <burddy-monorepo-spinner
            right
            *ngIf="data[0]"
          ></burddy-monorepo-spinner>
        </span>
      </burddy-monorepo-button>

      <a class="clickable facebook" (click)="connectWithFacebook()">
        <div class="-row -space-between">
          <img src="/assets/img/icons/facebook.svg" alt="facebook logo" />
          {{ 'FORM.LOGIN.FACEBOOK_CONNECT' | translate }}
        </div>
      </a>
    </div>
  </ng-container>
</ng-container>
