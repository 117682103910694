export type BaseValidation = {
  required: boolean;
  fieldName: string;
  maxLength?: number;
  minLength?: number;
  min?: number;
  max?: number;
  pattern?: RegExp[];
  phone?: boolean;
  email?: boolean;
};
