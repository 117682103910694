export enum AvailableOptions {
  insurance = 'insurance',
  b2bInsurance = 'b2bInsurance',
  day = 'day',
  b2cDay = 'b2cDay',
  b2bDay = 'b2bDay',
  b2cDelivery = 'b2cDelivery',
  b2bDelivery = 'b2bDelivery',
  props = 'props',
  virtualProps = 'virtualProps',
  deleteLogo = 'deleteLogo',
  goldenBook = 'goldenBook',
}

export const PRICES = {
  [AvailableOptions.insurance]: 29,
  [AvailableOptions.b2bInsurance]: 59,
  [AvailableOptions.day]: 99,
  [AvailableOptions.b2cDay]: 119,
  [AvailableOptions.b2bDay]: 119,
  [AvailableOptions.b2cDelivery]: 29,
  [AvailableOptions.b2bDelivery]: 229,
  [AvailableOptions.deleteLogo]: 10,
  [AvailableOptions.goldenBook]: 15,
  [AvailableOptions.virtualProps]: 15,
  [AvailableOptions.props]: 7,
};

export const NOT_SELECTABLE_OPTIONS = [
  AvailableOptions.insurance,
  AvailableOptions.b2bInsurance,
  AvailableOptions.b2cDelivery,
  AvailableOptions.b2bDelivery,
  AvailableOptions.day,
  AvailableOptions.b2cDay,
  AvailableOptions.b2bDay,
];

export const ONLY_FOR_B2B_OPTIONS = [
  AvailableOptions.b2bDay,
  AvailableOptions.b2bInsurance,
  AvailableOptions.b2bDelivery,
];

export const ONLY_FOR_B2C_OPTIONS = [
  AvailableOptions.b2cDelivery,
  AvailableOptions.b2cDay,
];

export const MANDATORY_OPTIONS = [
  AvailableOptions.insurance,
  AvailableOptions.b2bInsurance,
  AvailableOptions.b2bDelivery,
  AvailableOptions.b2cDay,
  AvailableOptions.b2bDay,
];
