import { BaseValidation } from './base.validation';

export const EVENT_NAME = {
  FIELD_NAME: 'eventName',
  MIN_LENGTH: 3,
  MAX_LENGTH: 100,
  REQUIRED: true,
};

export const NUMBER_OF_PRINTS = {
  FIELD_NAME: 'numberOfPrints',
  MIN: 1,
  MAX: 10,
  REQUIRED: true,
};

export const WANT_TO_SEND_EMAIL = {
  FIELD_NAME: 'wantToSendMail',
  REQUIRED: true,
};

export const MAIL_OBJECT = {
  FIELD_NAME: 'mailObject',
  MIN_LENGTH: 3,
  MAX_LENGTH: 100,
  REQUIRED: false,
};

export const MAIL_CONTENT = {
  FIELD_NAME: 'mailContent',
  MIN_LENGTH: 3,
  MAX_LENGTH: 400,
  REQUIRED: false,
};

export function getBookingCustomizationRules(): BookingCustomizationRules {
  return {
    eventName: {
      maxLength: EVENT_NAME.MAX_LENGTH,
      minLength: EVENT_NAME.MIN_LENGTH,
      required: EVENT_NAME.REQUIRED,
      fieldName: EVENT_NAME.FIELD_NAME,
    },
    numberOfPrints: {
      min: NUMBER_OF_PRINTS.MIN,
      max: NUMBER_OF_PRINTS.MAX,
      required: NUMBER_OF_PRINTS.REQUIRED,
      fieldName: NUMBER_OF_PRINTS.FIELD_NAME,
    },
    wantToSendMail: {
      required: WANT_TO_SEND_EMAIL.REQUIRED,
      fieldName: WANT_TO_SEND_EMAIL.FIELD_NAME,
    },
    mailObject: {
      minLength: MAIL_OBJECT.MIN_LENGTH,
      maxLength: MAIL_OBJECT.MAX_LENGTH,
      required: MAIL_OBJECT.REQUIRED,
      fieldName: MAIL_OBJECT.FIELD_NAME,
    },
    mailContent: {
      minLength: MAIL_CONTENT.MIN_LENGTH,
      maxLength: MAIL_CONTENT.MAX_LENGTH,
      required: MAIL_CONTENT.REQUIRED,
      fieldName: MAIL_CONTENT.FIELD_NAME,
    },
  };
}

// INTERFACE
export interface BookingCustomizationRules {
  eventName: BaseValidation;
  numberOfPrints: BaseValidation;
  wantToSendMail: BaseValidation;
  mailObject: BaseValidation;
  mailContent: BaseValidation;
}
