import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'burddy-monorepo-check-box',
  standalone: true,
  imports: [NgIf],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: CheckBoxComponent, multi: true },
  ],
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckBoxComponent implements ControlValueAccessor {
  @Input() public label!: string;
  @Input() public disabled?: boolean;
  @Input() public alignement?: 'left' | 'center' | 'right' = 'center';

  checked = false;

  @ViewChild('input', { static: false }) input!: ElementRef;

  onChange!: (...arg: unknown[]) => unknown;
  onTouched!: (...arg: unknown[]) => unknown;

  writeValue(obj: any): void {
    this.checked = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  changeValue() {
    this.checked = !this.checked;
    this.onChange(this.checked);
    this.onTouched();
  }
}
