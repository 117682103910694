import { DatePipe, NgClass, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from '@angular/core';
import {
  BadgeComponent,
  ButtonComponent,
} from '@burddy-monorepo/front/shared/ui-kit';
import { BookingData, OrderStatus } from '@burddy-monorepo/shared/shared-data';
import { TranslateModule } from '@ngx-translate/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';

import { CardComponent } from '../card/card.component';

@Component({
  selector: 'burddy-monorepo-booking-card',
  standalone: true,
  imports: [
    NgIf,
    CardComponent,
    BadgeComponent,
    NgClass,
    TranslateModule,
    ButtonComponent,
  ],
  providers: [DatePipe],
  templateUrl: './booking-card.component.html',
  styleUrls: ['./booking-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingCardComponent {
  @Input() booking!: BookingData;

  private _datePipe = inject(DatePipe);
  private _store = inject(Store);

  get isPassedOrder(): boolean {
    return (
      (this.booking?.selectedEventDate?.getTime() ?? 0) < new Date().getTime()
    );
  }
  get classOfStatus(): 'warning' | 'success' | 'error' {
    switch (this.booking.status) {
      case OrderStatus.Complete:
      case OrderStatus.AddingOptionsPayementComplete:
        return 'success';
      case OrderStatus.Pending:
      case OrderStatus.AddingOptionsPayementPending:
        return 'warning';
      case OrderStatus.AddingOptionsPayementFailed:
      case OrderStatus.Delete:
      case OrderStatus.Failed:
        return 'error';
      default:
        return 'error';
    }
  }

  get optionsQuantity(): number {
    return (
      [...(this.booking?.options?.entries() ?? [])].reduce(
        (acc, [key, value]) => {
          return acc + value;
        },
        0
      ) ?? 0
    );
  }
  get printValue(): number {
    return this.booking?.selectedPrints?.quantity ?? 0;
  }

  get eventName(): string {
    if (this.booking?.configs?.length) {
      return this.booking.configs[0].eventName ?? '';
    }
    return '';
  }

  get dateTitle(): string {
    if (!this.booking) {
      return '';
    }
    if (this.booking.duration && this.booking.duration > 1) {
      return `${this.booking.duration} days`;
    } else {
      return (
        this._datePipe.transform(
          this.booking.selectedEventDate,
          'd MMMM yyyy'
        ) ?? ''
      );
    }
  }

  editBooking() {
    // TODO : to implement
  }

  editConfig() {
    this._store.dispatch([
      new Navigate([`customize-booking/${this.booking?.id}/main-info`]),
    ]); // TODO: refacto to use enum when in features libs
  }

  downloadInvoice() {
    // TODO : to implement
  }
}
