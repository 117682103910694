import {
  MIN_8_CHARACTERS_REGEX,
  WITH_AT_LEAST_1_NUMBER_REGEX,
  WITH_AT_LEAST_1_SPECIAL_CHARACTER_REGEX,
  WITH_AT_LEAST_1_UPPERCASE_REGEX,
} from '../constants';
import { BaseValidation } from './base.validation';

export const OLD_PASSWORD_MIN_LENGTH = 4;

export const LOGIN = {
  FIELD_NAME: 'login',
  MIN_LENGTH: 3,
  MAX_LENGTH: 50,
  REQUIRED: true,
};

export const PASSWORD = {
  FIELD_NAME: 'password',
  MIN_LENGTH: 8,
  MAX_LENGTH: 50,
  REQUIRED: true,
};

export const CONFIRM_PASSWORD = {
  FIELD_NAME: 'confirmPassword',
  MIN_LENGTH: 8,
  MAX_LENGTH: 50,
  REQUIRED: true,
};
export function getLoginFormRules(forCreation: boolean): LoginFormRules {
  const passwordRegex = [
    MIN_8_CHARACTERS_REGEX,
    WITH_AT_LEAST_1_UPPERCASE_REGEX,
    WITH_AT_LEAST_1_SPECIAL_CHARACTER_REGEX,
    WITH_AT_LEAST_1_NUMBER_REGEX,
  ];
  return {
    login: {
      minLength: LOGIN.MIN_LENGTH,
      maxLength: LOGIN.MAX_LENGTH,
      required: LOGIN.REQUIRED,
      fieldName: LOGIN.FIELD_NAME,
      email: true,
    },
    password: {
      minLength: forCreation ? PASSWORD.MIN_LENGTH : OLD_PASSWORD_MIN_LENGTH, // use 4 to allow old password
      maxLength: PASSWORD.MAX_LENGTH,
      required: PASSWORD.REQUIRED,
      fieldName: PASSWORD.FIELD_NAME,
      pattern: [...(forCreation ? passwordRegex : [])],
    },
    confirmPassword: {
      minLength: CONFIRM_PASSWORD.MIN_LENGTH,
      maxLength: CONFIRM_PASSWORD.MAX_LENGTH,
      required: forCreation ? CONFIRM_PASSWORD.REQUIRED : false,
      fieldName: CONFIRM_PASSWORD.FIELD_NAME,
      pattern: passwordRegex,
    },
  };
}

// INTERFACE
export interface LoginFormRules {
  login: BaseValidation;
  password: BaseValidation;
  confirmPassword: BaseValidation;
}
