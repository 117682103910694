import { CustomerData } from '@burddy-monorepo/shared/shared-data';

export class LoadCustomerBookings {
  public static readonly type = '[Customer] LoadCustomerBookings';
}

export class SetCustomerInfo {
  public static readonly type = '[Customer] SetCustomerInfo';
  constructor(public data: CustomerData) {}
}

export class ClearUserData {
  public static readonly type = '[Customer] ClearData';
}
