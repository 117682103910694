import { DeliveryAvailableCountries } from '../../enums';

export interface LogCustomerOutDto {
  customer: {
    id: number;
    email: string;
    userName: string;
    streetAndNumber?: string;
    zipCode?: string;
    city?: string;
    country?: DeliveryAvailableCountries;
  };
  jwt: string;
  jwtExpirationDate: Date;
}
