import { AsyncPipe, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  inject,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GoNextStep, LoginState } from '@burddy-monorepo/front/shared/data';
import { AlertBoxComponent, ModalService } from '@burddy-monorepo/front/shared/ui-kit';
import { TranslateModule } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { combineLatest, Observable } from 'rxjs';

import { CardComponent } from '../../content/card/card.component';
import { LoginFormComponent } from '../../forms/login-form/login-form.component';

@Component({
  selector: 'burddy-monorepo-log-or-create',
  standalone: true,
  imports: [
    TranslateModule,
    AlertBoxComponent,
    LoginFormComponent,
    NgIf,
    AsyncPipe,
    CardComponent,
  ],
  templateUrl: './log-or-create.component.html',
  styleUrls: ['./log-or-create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogOrCreateComponent implements OnInit {
  @Select(LoginState.loginFormGroup)
  public loginFormGroup$!: Observable<FormGroup>;

  @Select(LoginState.createLoginFormGroup)
  public createLoginFormGroup$!: Observable<FormGroup>;

  @Select(LoginState.showBadCredentialsMessage)
  showBadCredentialsMessage$!: Observable<boolean | undefined>;

  @ViewChild('createLogin') createLoginForm!: TemplateRef<LoginFormComponent>;

  @Input() showedInBookingFlow = false;

  public data$!: Observable<[FormGroup, boolean | undefined, FormGroup]>;

  private _store = inject(Store);
  private _modalForm!: ComponentRef<any> | undefined;
  private _modalService = inject(ModalService);
  ngOnInit(): void {
    this.data$ = combineLatest([
      this.loginFormGroup$,
      this.showBadCredentialsMessage$,
      this.createLoginFormGroup$,
    ]);
  }

  userIsConnected() {
    if (this.showedInBookingFlow) {
      this._store.dispatch(new GoNextStep());
    }
    if (this._modalForm) {
      this._modalService.closeModal(this._modalForm);
      this._modalForm = undefined;
    }
  }

  goToCreateAccount() {
    this._modalForm = this._modalService.openModal({
      template: this.createLoginForm,
    }) as ComponentRef<any>;
  }
}
