<div class="modal-backdrop" (click)="close()"></div>
<div class="modal {{ size }}">
  <div *ngIf="title" class="header">
    {{ title }}
    <span *ngIf="showCloseButton" (click)="close()" class="modal-close"
      ><img src="./assets/img/icons/cross.svg" alt="cross"
    /></span>
  </div>
  <div class="body">
    <ng-content #modalContent></ng-content>
  </div>
</div>
