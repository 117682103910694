import { inject, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CookiesEnum } from '@burddy-monorepo/front/shared/enums';
import { LoginFormGroup } from '@burddy-monorepo/front/shared/form-groups';
import { CustomersService } from '@burddy-monorepo/front/shared/services';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { CookieService } from 'ngx-cookie-service';
import { catchError, tap, throwError } from 'rxjs';

import {
  ClearBookingCustomizationData,
  ClearUserData,
  LogCustomerWithCredentials,
  LogoutCustomer,
  SetCustomerInfo,
  SetLoginForm,
} from '../actions';
import { LoginStateModel } from '../models/login.model';

@State<LoginStateModel>({
  name: 'login',
  defaults: {
    loginForm: undefined,
    showBadCredentialsMessage: false,
    callingService: false,
  },
})
@Injectable()
export class LoginState {
  private _customersService = inject(CustomersService);
  private _store = inject(Store);
  private _cookieService = inject(CookieService);
  @Selector()
  public static loginFormGroup(state: LoginStateModel): FormGroup | undefined {
    return state.loginForm?.get('loginForm') as LoginFormGroup | undefined;
  }

  @Selector()
  public static createLoginFormGroup(
    state: LoginStateModel
  ): FormGroup | undefined {
    return state.loginForm?.get('createLoginForm') as
      | LoginFormGroup
      | undefined;
  }

  @Selector()
  public static showBadCredentialsMessage(
    state: LoginStateModel
  ): boolean | undefined {
    return state.showBadCredentialsMessage;
  }

  @Selector()
  public static callingService(state: LoginStateModel): boolean | undefined {
    return state.callingService;
  }

  @Action(SetLoginForm)
  public setLoginForm(
    { patchState }: StateContext<LoginStateModel>,
    { data }: SetLoginForm
  ): void {
    console.log('should set loginForm', data);

    patchState({ loginForm: data });
  }

  @Action(LogoutCustomer)
  public LogoutCustomer(): void {
    this._cookieService.delete(CookiesEnum.CUSTOMER_JWT);
    this._store.dispatch([
      new ClearUserData(),
      new ClearBookingCustomizationData(),
    ]);
  }

  @Action(LogCustomerWithCredentials)
  public logCustomerWithCredentials(
    { patchState }: StateContext<LoginStateModel>,
    { email, password }: LogCustomerWithCredentials
  ): void {
    patchState({ showBadCredentialsMessage: false, callingService: true });
    this._customersService
      .connectUser({ email, password })
      .pipe(
        tap(() => {
          patchState({ callingService: false });
        }),
        catchError((err) => {
          if (err.status === 404) {
            patchState({ showBadCredentialsMessage: true });
          }
          return throwError(() => new Error(err));
        })
      )
      .subscribe((_) => {
        if (_) {
          this._store.dispatch([
            new SetCustomerInfo({
              id: _.customer.id,
              email: _.customer.email,
              names: _.customer.userName,
              streetAndNumber: _.customer.streetAndNumber,
              zipCode: _.customer.zipCode,
              city: _.customer.city,
              country: _.customer.country,
            }),
          ]);
        }
      });
  }
}
