export enum BookingRoutes {
  SELECT_PRO_OR_PRIVATE = 'select-pro-or-private',
  SET_ADDRESS = 'set-address',
  SET_DATE = 'set-date',
  SET_HOURS = 'set-hours',
  SET_OPTIONS = 'set-options',
  SET_CONTACTS = 'set-contacts',
  SET_INVOICE = 'set-invoice',
  CONFIRMATION = 'confirmation',
  LOG_CUSTOMER = 'login',
  DETAILS = 'details',
}
