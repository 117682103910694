import { BookingCustomizationFormGroup } from '@burddy-monorepo/front/shared/form-groups';
import { BookingConfigData } from '@burddy-monorepo/shared/shared-data';

export interface BookingCustomizationStateModel {
  relatedBookingId?: string;
  customizationFormGroup?: BookingCustomizationFormGroup;
  callingService?: boolean;
  canEdit?: boolean;
  configs?: BookingConfigData[];
}
