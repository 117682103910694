import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONNEMENT, IEnvironment } from '@burddy-monorepo/front/shared/interfaces';
import {
  APIPathBooking,
  IGetCustomerOrdersResponse,
  IGetOrdersRequest,
  VerifyDateRequest,
  VerifyDateResponse,
} from '@burddy-monorepo/shared/shared-data';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  constructor(
    @Inject(ENVIRONNEMENT) private environment: IEnvironment,
    private httpService: HttpClient
  ) {}

  verifyDate(request: VerifyDateRequest): Observable<VerifyDateResponse> {
    return new Observable((observer) => {
      // TODO : take data from DB
      const deliveryDate = request.eventDate;
      deliveryDate.setDate(request.eventDate.getDate() - 1);

      const returnDate = request.eventDate;
      returnDate.setDate(request.eventDate.getDate() + 1);

      observer.next({
        isAvailable: true,
        deliveryDate: deliveryDate,
        returnDate: returnDate,
      });
      observer.complete();
    });
  }

  checkPromoCode(code: string): Observable<boolean> {
    return this.httpService.get<boolean>(
      `${this.environment.bookingAPI_URL}/${APIPathBooking.MAIN}/${APIPathBooking.CHECK_PROMO_CODE}?code=${code}`
    );
  }

  loadMyBookings(
    data: IGetOrdersRequest
  ): Observable<IGetCustomerOrdersResponse> {
    let params = new HttpParams();
    if (data) {
      Object.keys(data).forEach((key) => {
        params = params.set(key, encodeURI((data as any)[key] + ''));
      });
    }
    return this.httpService
      .get<IGetCustomerOrdersResponse>(
        `${this.environment.bookingAPI_URL}/${APIPathBooking.MAIN}/${APIPathBooking.MY_BOOKINGS}`,
        { params }
      )
      .pipe(
        map((_) => {
          return {
            ..._,
            bookings: _.bookings.map((booking) => {
              return {
                ...booking,
                selectedEventDate: new Date(booking.selectedEventDate + ''),
                options: new Map(Object.entries(booking.options as any)),
              };
            }),
          };
        })
      );
  }
}
