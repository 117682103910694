import { Route } from '@angular/router';
import { customizeRoutes } from 'apps/front/booking/src/features/customize-booking/container/customize-booking.routes';

import { bookingRoutes } from '../features/booking/containers/booking.routes';
import { loginRoutes } from '../features/login/containers/login.routes';
import { MainRoutes } from '../routes';

export const appRoutes: Route[] = [
  {
    path: MainRoutes.BOOKING,
    children: bookingRoutes,
    loadComponent: () =>
      import(
        '../features/booking/containers/booking-page/booking-page.component'
      ).then((m) => m.BookingPageComponent),
  },
  {
    path: MainRoutes.LOGIN,
    children: loginRoutes,
    loadComponent: () =>
      import(
        '../features/login/containers/login-page/login-page.component'
      ).then((m) => m.LoginPage),
  },
  {
    path: MainRoutes.MY_BOOKINGS,
    loadComponent: () =>
      import(
        '../features/my-bookings/containers/my-bookings-page/my-bookings-page.component'
      ).then((m) => m.MyBookingsPageComponent),
  },
  {
    path: MainRoutes.CUSTOMIZE_BOOKING,
    children: customizeRoutes,
    loadComponent: () =>
      import(
        '../features/customize-booking/container/customize-booking-page/customize-booking-page.component'
      ).then((m) => m.CustomizeBookingPageComponent),
  },
  { path: '**', redirectTo: 'booking', pathMatch: 'full' },
];
