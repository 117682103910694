export enum OrderPaymentMethod {
  American_Express = 'American Express',
  americanExpress = 'americanExpress',
  bancontact = 'bancontact',
  delay = 'delay',
  ideal = 'ideal',
  MasterCard = 'MasterCard',
  partner_mode = 'partner mode',
  paypal = 'paypal',
  promotion = 'promotion',
  stop_for_config = 'stop_for_config',
  Visa = 'Visa',
}
