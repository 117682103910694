import { HttpClient } from '@angular/common/http';
import { Inject, inject, Injectable } from '@angular/core';
import { CookiesEnum } from '@burddy-monorepo/front/shared/enums';
import { ENVIRONNEMENT, IEnvironment } from '@burddy-monorepo/front/shared/interfaces';
import { APIPathCustomers, LogCustomerInDto, LogCustomerOutDto } from '@burddy-monorepo/shared/shared-data';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomersService {
  private _http = inject(HttpClient);
  constructor(@Inject(ENVIRONNEMENT) private environment: IEnvironment) {}

  connectUser(data: LogCustomerInDto): Observable<LogCustomerOutDto> {
    return this._http
      .post<LogCustomerOutDto>(
        `${this.environment.bookingAPI_URL}/${APIPathCustomers.MAIN}/${APIPathCustomers.LOG}`,
        data
      )
      .pipe(
        tap((logInfo) => {
          document.cookie = `${CookiesEnum.CUSTOMER_JWT}=${
            logInfo.jwt
          }; expires=${new Date(
            logInfo.jwtExpirationDate
          )}; SameSite=None; Secure; path=/`;
        })
      );
  }
}
