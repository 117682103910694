import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonComponent } from '@burddy-monorepo/front/shared/ui-kit';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { HeaderState } from '../data/states';
import { BookingHeaderData } from '../features';
import { BookingHeaderComponent } from '../features/booking-header/booking-header.component';

@Component({
  standalone: true,
  imports: [
    RouterModule,
    BookingHeaderComponent,
    ButtonComponent,
    AsyncPipe,
    NgIf,
  ],
  selector: 'burddy-monorepo-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Burddy';
  @Select(HeaderState.headerData) headerData$!: Observable<BookingHeaderData>;

  constructor(private translateService: TranslateService) {}
  ngOnInit(): void {
    this.translateService.setDefaultLang('fr');
    this.translateService.currentLang = 'fr';
  }
}
