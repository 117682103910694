import { DeliveryAvailableCountries } from '../enums';

export interface AddressData {
  id?: string;
  streetName: string;
  streetNumber?: string;
  postalCode: string;
  city: string;
  country: DeliveryAvailableCountries;
  complementInfo?: string;
}
