import { inject, Injectable } from '@angular/core';
import { BookingService } from '@burddy-monorepo/front/shared/services';
import { BookingData } from '@burddy-monorepo/shared/shared-data';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError } from 'rxjs';

import { ClearUserData, LoadCustomerBookings, SetCustomerInfo } from '../actions';
import { CustomerStateModel } from '../models';

const NUMBER_OF_BOOKINGS_PER_PAGE = 50;

const getDefaultValue = () => {
  return {
    info: undefined,
    bookings: undefined,
    bookingsLoadedCurrentPage: 0,
    isLoadingBookings: false,
    hasMoreBookingsToLoad: true,
  };
};
@State<CustomerStateModel>({
  name: 'customer',
  defaults: getDefaultValue(),
})
@Injectable()
export class CustomerState {
  private _bookingService = inject(BookingService);
  @Selector()
  public static bookings(state: CustomerStateModel): BookingData[] | undefined {
    return state.bookings;
  }

  @Selector()
  public static isLoadingBookings(state: CustomerStateModel): boolean {
    return state.isLoadingBookings ?? false;
  }

  @Selector()
  public static hasConnectedUser(state: CustomerStateModel): boolean {
    return !!state.info;
  }
  @Action(SetCustomerInfo)
  public setCustomerInfo(
    { patchState }: StateContext<CustomerStateModel>,
    { data }: SetCustomerInfo
  ): void {
    patchState({ info: data });
  }

  @Action(ClearUserData)
  public clearUserData({ setState }: StateContext<CustomerStateModel>): void {
    setState(getDefaultValue());
  }
  @Action(LoadCustomerBookings)
  public loadCustomerBookings(ctx: StateContext<CustomerStateModel>): void {
    if (ctx.getState().hasMoreBookingsToLoad) {
      ctx.patchState({ isLoadingBookings: true });
      this._bookingService
        .loadMyBookings({
          numberPerPage: NUMBER_OF_BOOKINGS_PER_PAGE,
          currentPage: ctx.getState().bookingsLoadedCurrentPage ?? 0,
          withConfigs: true,
          withPrintInfo: true,
          withPropsInfo: true,
        })
        .pipe(
          catchError((error) => {
            ctx.patchState({ isLoadingBookings: false });
            throw error;
          })
        )
        .subscribe((_response) => {
          const { bookings, bookingsLoadedCurrentPage } = ctx.getState();
          ctx.patchState({
            bookingsLoadedCurrentPage: (bookingsLoadedCurrentPage ?? 0) + 1,
            bookings: (bookings ?? []).concat(_response?.bookings ?? []),
            hasMoreBookingsToLoad: _response?.hasMore,
            isLoadingBookings: false,
          });
        });
    }
  }
}
