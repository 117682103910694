export enum PossibleDeliveryHours {
  H_8_10 = 'H_8_10',
  H_9_11 = 'H_9_11',
  H_10_12 = 'H_10_12',
  H_11_13 = 'H_11_13',
  H_12_14 = 'H_12_14',
  H_13_15 = 'H_13_15',
  H_14_16 = 'H_14_16',
  H_15_17 = 'H_15_17',
  H_16_18 = 'H_16_18',
  H_17_19 = 'H_17_19',
  H_18_20 = 'H_18_20',
}
