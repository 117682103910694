import { BaseValidation } from './base.validation';

export const NAMES = {
  FIELD_NAME: 'names',
  MIN_LENGTH: 3,
  MAX_LENGTH: 50,
  REQUIRED: true,
};

export const EMAIL = {
  FIELD_NAME: 'email',
  MIN_LENGTH: 3,
  MAX_LENGTH: 50,
  REQUIRED: true,
};

export const PHONE = {
  FIELD_NAME: 'phone',
  MIN_LENGTH: 3,
  MAX_LENGTH: 50,
  REQUIRED: true,
};
export function getContactPersonRules(): ContactPersonRules {
  return {
    names: {
      minLength: NAMES.MIN_LENGTH,
      maxLength: NAMES.MAX_LENGTH,
      required: NAMES.REQUIRED,
      fieldName: NAMES.FIELD_NAME,
    },
    email: {
      minLength: EMAIL.MIN_LENGTH,
      maxLength: EMAIL.MAX_LENGTH,
      required: EMAIL.REQUIRED,
      fieldName: EMAIL.FIELD_NAME,
      email: true,
    },
    phone: {
      minLength: PHONE.MIN_LENGTH,
      maxLength: PHONE.MAX_LENGTH,
      required: PHONE.REQUIRED,
      fieldName: PHONE.FIELD_NAME,
      phone: true,
    },
  };
}

// INTERFACE
export interface ContactPersonRules {
  names: BaseValidation;
  email: BaseValidation;
  phone: BaseValidation;
}
