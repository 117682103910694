import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'burddy-monorepo-increment-decrement',
  standalone: true,
  imports: [NgIf, TranslateModule],
  templateUrl: './increment-decrement.component.html',
  styleUrls: ['./increment-decrement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IncrementDecrementComponent {
  @Input() minValue?: number;
  @Input() maxValue?: number;
  @Input() incrementLabel?: {
    singular: string;
    plural: string;
  };
  @Input() value = 0;
  @Output() valueChange = new EventEmitter<number>();

  increment() {
    if (this.maxValue && this.value + 1 > this.maxValue) return;
    this.value++;
    this.valueChange.emit(this.value);
  }

  decrement() {
    if (this.minValue && this.value - 1 < this.minValue) return;
    this.value--;
    this.valueChange.emit(this.value);
  }
}
