import { FormGroup } from '@angular/forms';

export class SetLoginForm {
  public static readonly type = '[Login] SetLoginForm';
  constructor(public data: FormGroup) {}
}

export class LogCustomerWithCredentials {
  public static readonly type = '[Login] LogCustomerWithCredentials';
  constructor(public email: string, public password: string) {}
}

export class LogoutCustomer {
  public static readonly type = '[Login] LogoutCustomer';
}
