import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'burddy-monorepo-alert-box',
  standalone: true,
  imports: [NgIf, NgSwitch, NgSwitchCase],
  templateUrl: './alert-box.component.html',
  styleUrls: ['./alert-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertBoxComponent {
  @Input() public type: 'success' | 'error' | 'warning' | 'info' | 'default' =
    'default';
  @Input() public showIcon = true;
  @Input() public customIcon?: string;
}
