import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const minDateValidator = (minDateToCheck: Date): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value === null || control.value === undefined) {
      return null;
    }
    const date = new Date(control.value);
    if (date < minDateToCheck) {
      return { minDate: true };
    }
    return null;
  };
};

export const minRangeDateValidator = (minDateToCheck: Date): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value === null || control.value === undefined) {
      return null;
    }
    const startDate = new Date(control.value.startDate);
    const endDate = new Date(control.value.startDate);
    if (startDate < minDateToCheck || endDate < minDateToCheck) {
      return { minDate: true };
    }
    return null;
  };
};
