import { AddressData, BookingData, BookingType } from '../booking';
import {
  AvailableOptions,
  B2B_MINIMUM_DAYS_BEFORE_EVENT,
  B2C_MINIMUM_DAYS_BEFORE_EVENT,
  ONLY_FOR_B2B_OPTIONS,
  ONLY_FOR_B2C_OPTIONS,
  PRICES,
} from '../constants';

export function addressAreSame(
  delivery: AddressData | undefined,
  returnAddress: AddressData | undefined
): boolean {
  return (
    !!delivery &&
    !!returnAddress &&
    ((delivery?.id &&
      returnAddress?.id &&
      delivery?.id === returnAddress?.id) ||
      (delivery?.city === returnAddress?.city &&
        delivery?.streetName === returnAddress?.streetName &&
        delivery?.postalCode === returnAddress?.postalCode &&
        delivery?.country === returnAddress?.country &&
        delivery?.streetNumber === returnAddress?.streetNumber))
  );
}

export function initNewBooking(bookingType: BookingType): BookingData {
  return {
    options: new Map<string, number>([
      [AvailableOptions.insurance, 1],
      [AvailableOptions.b2cDay, 1],
    ]),
    infoForUser: {},
  };
}

export function calculateBookingPrice(data?: BookingData): number {
  let total = 0;
  if (data) {
    total = Array.from(data?.options?.entries() || []).reduce(
      (acc, [key, value]) => {
        if (!(key in PRICES)) {
          return acc;
        }

        return acc + (PRICES[key as keyof typeof PRICES] ?? 0) * (value ?? 0);
      },
      total
    );

    total = total + (data?.selectedPrints?.price ?? 0);

    if (total < 0) {
      return 0;
    }
  }
  return total;
}

export function getMinimumDate(): Date {
  const date = new Date();
  date.setDate(date.getDate() + 1);
  return date;
}

export function compareDate(
  firstDate: Date | undefined,
  lastDate: Date | undefined
): number {
  if (!firstDate || !lastDate) return 0;
  firstDate.setHours(12, 0, 0, 0);
  lastDate.setHours(12, 0, 0, 0);
  if (
    firstDate.getMonth() === lastDate.getMonth() &&
    firstDate.getFullYear() === lastDate.getFullYear() &&
    firstDate.getDate() === lastDate.getDate()
  ) {
    return 0;
  }
  const diffTime = lastDate.getTime() - firstDate.getTime();
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
}

export function formatDate(date: Date): string {
  const month = date.getMonth() + 1;
  return `${date.getFullYear()}-${
    month >= 10 ? month : '0' + month
  }-${date.getDate()}`;
}

export function filterOptionBasedOnBookingType(
  bookingType: string
): (
  value: [string, number],
  index: number,
  array: [string, number][]
) => unknown {
  return ([key, value]) => {
    if (
      bookingType == 'b2c' &&
      ONLY_FOR_B2B_OPTIONS.includes(key as AvailableOptions)
    ) {
      return false;
    }

    if (
      bookingType == 'b2b' &&
      ONLY_FOR_B2C_OPTIONS.includes(key as AvailableOptions)
    ) {
      return false;
    }

    return true;
  };
}

export function getPossibleDeliveryDateForB2BEventDate(
  eventDate: Date
): Date[] {
  if (!eventDate) {
    return [];
  }

  const possibleDeliveryDates: Date[] = [];
  const eventDateDay = eventDate.getDay();
  if (eventDateDay !== 6 && eventDateDay !== 7) {
    possibleDeliveryDates.push(eventDate);
  }
  if (eventDateDay !== 1 && eventDateDay !== 7) {
    possibleDeliveryDates.push(addDaysToDate(eventDate, -1));
  }
  if (eventDateDay !== 2 && eventDateDay !== 1) {
    possibleDeliveryDates.push(addDaysToDate(eventDate, -2));
  }
  if (eventDateDay !== 2 && eventDateDay !== 3) {
    possibleDeliveryDates.push(addDaysToDate(eventDate, -3));
  }
  if (eventDateDay !== 3 && eventDateDay !== 4) {
    possibleDeliveryDates.push(addDaysToDate(eventDate, -4));
  }
  if (eventDateDay !== 4 && eventDateDay !== 5) {
    possibleDeliveryDates.push(addDaysToDate(eventDate, -5));
  }
  if (eventDateDay !== 5 && eventDateDay !== 6) {
    possibleDeliveryDates.push(addDaysToDate(eventDate, -6));
  }
  if (eventDateDay !== 6 && eventDateDay !== 7) {
    possibleDeliveryDates.push(addDaysToDate(eventDate, -7));
  }
  return possibleDeliveryDates
    .sort((a, b) => b.getTime() - a.getTime())
    .slice(0, 3);
}

export function getPossibleReturnDateForB2BEventDate(eventDate: Date): Date[] {
  if (!eventDate) {
    return [];
  }

  const possibleReturnDates: Date[] = [];
  const eventDateDay = eventDate.getDay();
  if (eventDateDay !== 6 && eventDateDay !== 7) {
    possibleReturnDates.push(eventDate);
  }
  if (eventDateDay !== 5 && eventDateDay !== 6) {
    possibleReturnDates.push(addDaysToDate(eventDate, 1));
  }
  if (eventDateDay !== 4 && eventDateDay !== 5) {
    possibleReturnDates.push(addDaysToDate(eventDate, 2));
  }
  if (eventDateDay !== 3 && eventDateDay !== 4) {
    possibleReturnDates.push(addDaysToDate(eventDate, 3));
  }
  if (eventDateDay !== 2 && eventDateDay !== 3) {
    possibleReturnDates.push(addDaysToDate(eventDate, 4));
  }
  if (eventDateDay !== 1 && eventDateDay !== 2) {
    possibleReturnDates.push(addDaysToDate(eventDate, 5));
  }
  if (eventDateDay !== 7 && eventDateDay !== 1) {
    possibleReturnDates.push(addDaysToDate(eventDate, 6));
  }
  if (eventDateDay !== 6 && eventDateDay !== 7) {
    possibleReturnDates.push(addDaysToDate(eventDate, 7));
  }
  return possibleReturnDates
    .sort((a, b) => a.getTime() - b.getTime())
    .slice(0, 3);
}

export function addDaysToDate(eventDate: Date, days: number): Date {
  const date = new Date(eventDate);
  date.setDate(date.getDate() + days);
  return date;
}
export function addMinutesToDate(date: Date, minutes: number): Date {
  const dateToReturn = new Date(date);
  date.setMinutes(date.getMinutes() + minutes);
  return dateToReturn;
}

export function canEditBooking(
  eventDate: Date,
  bookingType: BookingType
): boolean {
  return (
    addDaysToDate(
      eventDate,
      -(bookingType === 'b2c'
        ? B2C_MINIMUM_DAYS_BEFORE_EVENT
        : B2B_MINIMUM_DAYS_BEFORE_EVENT)
    ).getTime() > new Date().getTime()
  );
}

export function getEventDatesTitle(
  eventDate: Date | undefined,
  duration?: number
): string {
  if (!eventDate) return '';

  return `${eventDate.getDate()} {{MONTH.${eventDate.getMonth()}}} ${eventDate.getFullYear()}`;
}
