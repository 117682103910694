import { AbstractControl, ValidatorFn } from '@angular/forms';
import { EMAIL_REGEX } from '@burddy-monorepo/shared/shared-data';

export const emailValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control.value === null || control.value === undefined) {
      return null;
    }
    const match = control.value.match(EMAIL_REGEX);
    if (!match || match.length === 0) {
      return { INVALID_EMAIL: true };
    }

    return null;
  };
};
