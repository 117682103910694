<div class="component-content" [class]="'-' + type">
  <div class="left">
    <div *ngIf="showIcon">
      <span [ngSwitch]="type" *ngIf="!customIcon">
        <img
          *ngSwitchCase="'error'"
          src="/assets/img/icons/attention.svg"
          alt="error icon"
        />
        <img
          *ngSwitchCase="'warning'"
          src="/assets/img/icons/alert.svg"
          alt="alert icon"
        />
      </span>
      <span *ngIf="customIcon">
        <img
          [src]="'/assets/img/icons/' + customIcon + '.svg'"
          [alt]="customIcon"
        />
      </span>
    </div>
    <div>
      <ng-content></ng-content>
    </div>
  </div>
  <div class="right">
    <ng-content select="[right]"></ng-content>
  </div>
</div>
