import { Route } from '@angular/router';

import { LoginRoutes } from '../login-routes.enum';

export const loginRoutes: Route[] = [
  {
    path: LoginRoutes.LOG_OR_CREATE,
    loadComponent: () =>
      import('@burddy-monorepo/front/shared/components').then(
        (m) => m.LogOrCreateComponent
      ),
  },
  {
    path: '**',
    redirectTo: LoginRoutes.LOG_OR_CREATE,
    pathMatch: 'full',
  },
];
