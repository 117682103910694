import { DeliveryAvailableCountries } from '../enums/delivery-available-countries.enum';

export interface CustomerData {
  id?: number;
  names: string;
  email?: string;
  phone?: string;
  streetAndNumber?: string;
  zipCode?: string;
  city?: string;
  country?: DeliveryAvailableCountries;
}
