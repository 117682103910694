import { DOCUMENT } from '@angular/common';
import {
  ComponentFactoryResolver,
  ComponentRef,
  createComponent,
  EnvironmentInjector,
  Inject,
  Injectable,
  Injector,
  OnDestroy,
  TemplateRef,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { ModalComponent } from './modal.component';

export interface IModalContent {
  component?: any;
  template?: TemplateRef<any>;
  options?: {
    size?: 'small' | 'md' | 'lg';
    title?: string;
    showCloseButton?: boolean;
    injector?: Injector;
  };
}
@Injectable({ providedIn: 'root' })
export class ModalService implements OnDestroy {
  private componentRef!: ComponentRef<ModalComponent>;
  private _destroyed$ = new Subject<void>();
  private modalNotifier?: Subject<string>;
  constructor(
    private resolver: ComponentFactoryResolver,
    private injector: Injector,
    private environmentInjector: EnvironmentInjector,
    @Inject(DOCUMENT) private document: Document
  ) {}
  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  openModal(
    data: IModalContent
  ): ComponentRef<any> | ComponentRef<ModalComponent> | null | undefined {
    let modalComponent:
      | ComponentRef<any>
      | ComponentRef<ModalComponent>
      | null = null;
    if (data.component) {
      const componentRef = createComponent(data.component, {
        environmentInjector: this.environmentInjector,
      });
      componentRef.hostView.detectChanges();
      modalComponent = createComponent<ModalComponent>(ModalComponent, {
        environmentInjector: this.environmentInjector,
        projectableNodes: [[componentRef.location.nativeElement]],
      });

      modalComponent.instance.size = data.options?.size ?? 'md';
    } else if (data.template) {
      const modalCompoendFactory =
        this.resolver.resolveComponentFactory(ModalComponent);
      const contentViewRef = data.template.createEmbeddedView(
        data?.options?.injector ?? this.injector
      );
      contentViewRef.detectChanges();
      modalComponent = modalCompoendFactory.create(
        data?.options?.injector ?? this.injector,
        [contentViewRef.rootNodes]
      );
    }

    if (modalComponent) {
      modalComponent.instance.size = data.options?.size ?? 'md';
      modalComponent.instance.title = data.options?.title ?? '';
      modalComponent.instance.showCloseButton =
        data.options?.showCloseButton ?? true;
      modalComponent.instance.closeEvent
        .pipe(takeUntil(this._destroyed$))
        .subscribe(() => this.closeModal());

      modalComponent.instance.submitEvent
        .pipe(takeUntil(this._destroyed$))
        .subscribe(() => this.confirm());

      modalComponent.hostView.detectChanges();
      this.document.body.appendChild(modalComponent.location.nativeElement);
    }

    this.modalNotifier = new Subject<string>();
    return modalComponent;
  }

  closeModal(modalToClose?: ComponentRef<ModalComponent>) {
    this.modalNotifier?.complete();
    if (modalToClose) {
      modalToClose.instance.forceClose();
    }
  }

  confirm() {
    this.modalNotifier?.next('confirm');
    this.closeModal();
  }
}
