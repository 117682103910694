import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CustomerState, LogCustomerWithCredentials, LoginState } from '@burddy-monorepo/front/shared/data';
import { ShowInputErrorDirective } from '@burddy-monorepo/front/shared/directives';
import { LoginFormGroup } from '@burddy-monorepo/front/shared/form-groups';
import { ButtonComponent, SpinnerComponent } from '@burddy-monorepo/front/shared/ui-kit';
import { getLoginFormRules, LOGIN, PASSWORD } from '@burddy-monorepo/shared/shared-data';
import { TranslateModule } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { combineLatest, Observable } from 'rxjs';

@Component({
  selector: 'burddy-monorepo-login-form',
  standalone: true,
  imports: [
    NgIf,
    TranslateModule,
    ReactiveFormsModule,
    ShowInputErrorDirective,
    ButtonComponent,
    SpinnerComponent,
    AsyncPipe,
  ],
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginFormComponent implements OnInit {
  @Select(LoginState.callingService)
  public callingService$!: Observable<boolean | undefined>;

  @Input() title?: string;
  @Input() formGroup!: LoginFormGroup;
  @Output() connectEvent = new EventEmitter<void>();

  public loginRules = getLoginFormRules(false);
  public data$!: Observable<[boolean | undefined]>;
  private _store = inject(Store);

  ngOnInit(): void {
    this.initForm();
    this.data$ = combineLatest([this.callingService$]);
  }

  initForm() {
    if (!this.formGroup) {
      throw new Error(`Form group not found`);
    }

    if (!(this.formGroup instanceof LoginFormGroup)) {
      throw new Error(`Form group is not an instance of LoginFormGroup`);
    }
  }

  goToPasswordRecovery() {
    // TODO implement
  }

  connect() {
    this._store
      .dispatch(
        new LogCustomerWithCredentials(
          this.formGroup.get(LOGIN.FIELD_NAME)?.value,
          this.formGroup.get(PASSWORD.FIELD_NAME)?.value
        )
      )
      .subscribe(() => {
        if (this._store.selectSnapshot(CustomerState.hasConnectedUser)) {
          this.connectEvent.emit();
        }
      });
  }

  connectWithFacebook() {
    // TODO: implement
  }
}
