import { ValidatorFn, Validators } from '@angular/forms';
import { BaseValidation } from '@burddy-monorepo/shared/shared-data';

import { emailValidator } from './email.validator';
import { phoneValidator } from './phone.validator';

export function getValidatorsBasedOnRules(
  rules: BaseValidation
): ValidatorFn[] {
  return [
    ...(rules.required ? [Validators.required] : []),
    ...(rules.maxLength ? [Validators.maxLength(rules.maxLength)] : []),
    ...(rules.minLength ? [Validators.minLength(rules.minLength)] : []),
    ...(rules.min ? [Validators.minLength(rules.min)] : []),
    ...(rules.max ? [Validators.minLength(rules.max)] : []),
    ...(rules.pattern
      ? [...rules.pattern.map((_) => Validators.pattern(_))]
      : []),
    ...(rules.phone ? [phoneValidator()] : []),
    ...(rules.email ? [emailValidator()] : []),
  ];
}
