<ng-container *ngIf="bookings">
  <div class="-cards-list" #cardsList>
    <burddy-monorepo-card class="clickable" (click)="addBooking()">
      <div class="center -column">
        <button>+</button>
        <h3>{{ 'COMPONENTS.BOOKING_CARDS_LIST.ADD_BOOKING' | translate }}</h3>
      </div>
    </burddy-monorepo-card>
    <burddy-monorepo-booking-card
      *ngFor="let booking of bookings"
      [booking]="booking"
    ></burddy-monorepo-booking-card>
  </div>
</ng-container>
