import { AddressData } from '../address-data.interface';
import { BookingType } from '../booking-data.interface';
import { PrintDto } from './print.interface';

export interface ICreateOrderRequest {
  selectedEventDate: string;
  duration: number;
  deliveryAddress: AddressData;
  returnAddress: AddressData;
  bookingType: BookingType;
  selectedPrints?: PrintDto;
  options?: Map<string, number>;
}
