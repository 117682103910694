import { NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { ShowInputErrorDirective } from '@burddy-monorepo/front/shared/directives';
import {
  DeliveryAvailableCountries,
  getAddressRules,
} from '@burddy-monorepo/shared/shared-data';
import { TranslateModule } from '@ngx-translate/core';

import { AddressFormGroup } from './addressFormGroup';

@Component({
  selector: 'burddy-monorepo-address-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    NgIf,
    ShowInputErrorDirective,
    NgFor,
  ],
  providers: [FormGroupDirective, NgFor],
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressFormComponent implements OnInit {
  @Input()
  formGroup!: AddressFormGroup;

  @Input()
  showComplement?: boolean;

  public addressRules = getAddressRules();
  public availableCountries = Object.values(DeliveryAvailableCountries);

  constructor(private rootFormGroup: FormGroupDirective) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    if (!this.formGroup) {
      throw new Error(`Form group not found`);
    }

    if (!(this.formGroup instanceof AddressFormGroup)) {
      throw new Error(`Form group is not an instance of AddressFormGroup`);
    }
  }
}
