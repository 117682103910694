<div class="overlay" [class.visible]="opened"></div>
<div class="component-content" [ngClass]="{ '-open': opened }">
  <div class="top">
    <div *ngIf="isOpenable" id="hook-line"></div>
    <ng-content *ngIf="opened" select="[detail]"></ng-content>
  </div>
  <div class="bottom">
    <ng-content select="[preview]"></ng-content>
  </div>
</div>
