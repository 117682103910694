import { NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ShowInputErrorDirective } from '@burddy-monorepo/front/shared/directives';
import { InputTelComponent } from '@burddy-monorepo/front/shared/ui-kit';
import { getContactPersonRules } from '@burddy-monorepo/shared/shared-data';
import { TranslateModule } from '@ngx-translate/core';

import { ContactFormGroup } from './contactFormGroup';

@Component({
  selector: 'burddy-monorepo-contact-form',
  standalone: true,
  imports: [
    TranslateModule,
    InputTelComponent,
    ReactiveFormsModule,
    NgIf,
    ShowInputErrorDirective,
    NgFor,
  ],
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactFormComponent implements OnInit {
  @Input() title?: string;
  @Input() formGroup!: ContactFormGroup;

  public contactRules = getContactPersonRules();

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    if (!this.formGroup) {
      throw new Error(`Form group not found`);
    }

    if (!(this.formGroup instanceof ContactFormGroup)) {
      throw new Error(`Form group is not an instance of ContactFormGroup`);
    }
  }
}
