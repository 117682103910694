import { AbstractControl, ValidatorFn } from '@angular/forms';
import { PhoneNumber, PhoneNumberUtil } from 'google-libphonenumber';

export const phoneValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const util = PhoneNumberUtil.getInstance();
    if (control.value === null || control.value === undefined) {
      return null;
    }
    let formattedPhoneNumber: PhoneNumber;
    try {
      formattedPhoneNumber = util.parseAndKeepRawInput(control.value);
    } catch (error) {
      return { INVALID_PHONE_NUMBER: true };
    }
    if (!util.isValidNumber(formattedPhoneNumber)) {
      return { INVALID_PHONE_NUMBER: true };
    }
    return null;
  };
};
