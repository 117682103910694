import { FormControl, FormGroup } from '@angular/forms';
import { getValidatorsBasedOnRules } from '@burddy-monorepo/front/shared/validators';
import { getLoginFormRules } from '@burddy-monorepo/shared/shared-data';

export interface LoginFormGroupInitValues {
  login: string;
  password: string;
  confirmPassword: string;
}
export class LoginFormGroup extends FormGroup {
  constructor(init?: LoginFormGroupInitValues, isForCreation?: boolean) {
    const rules = getLoginFormRules(!!isForCreation);
    super({
      [rules.login.fieldName]: new FormControl(
        init?.login,
        getValidatorsBasedOnRules(rules.login)
      ),
      [rules.password.fieldName]: new FormControl(
        init?.password,
        getValidatorsBasedOnRules(rules.password)
      ),
      ...(isForCreation
        ? {
            [rules.confirmPassword.fieldName]: new FormControl(
              init?.confirmPassword,
              getValidatorsBasedOnRules(rules.confirmPassword)
            ),
          }
        : {}),
    });
  }
}
