import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AddressFormGroup, ContactFormGroup } from '@burddy-monorepo/front/shared/components';
import {
  AddReturnAddressFormGroup,
  CheckPromoCode,
  ClearData,
  ClickOnPrimaryButton,
  GetIsActive,
  GoNextStep,
  InitBooking,
  PrepareBookingForm,
  RemoveReturnAddressFormGroup,
  SetBookingFormGroup,
  SetBookingStep,
  SetBookingType,
  SetDuration,
  SetIsLoading,
  SetMandatoryOptions,
  SetOptions,
  SetPossibleDeliveryDates,
  SetPossibleReturnDates,
  SetPrints,
  VerifyAddress,
  VerifyDate,
} from '@burddy-monorepo/front/shared/data';
import { AddressService, BookingService } from '@burddy-monorepo/front/shared/services';
import { DateRange } from '@burddy-monorepo/front/shared/ui-kit';
import { addressFormGroupToAddressData } from '@burddy-monorepo/front/shared/utils';
import { minRangeDateValidator, uniqueContactValidator } from '@burddy-monorepo/front/shared/validators';
import {
  addressAreSame,
  AddressData,
  AvailableOptions,
  BookingType,
  calculateBookingPrice,
  compareDate,
  EMAIL,
  filterOptionBasedOnBookingType,
  formatDate,
  getAddressRules,
  getMinimumDate,
  getPossibleDeliveryDateForB2BEventDate,
  getPossibleReturnDateForB2BEventDate,
  IContactPerson,
  initNewBooking,
  IVerifyAddressRequest,
  MANDATORY_OPTIONS,
  NAMES,
  NOT_SELECTABLE_OPTIONS,
  ONLY_FOR_B2B_OPTIONS,
  ONLY_FOR_B2C_OPTIONS,
  PHONE,
  PRICES,
  PrintDto,
} from '@burddy-monorepo/shared/shared-data';
import { TranslateService } from '@ngx-translate/core';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { catchError, of, Subscription, take, tap } from 'rxjs';

import { BookingRoutes } from '../../features/booking/booking-routes.enum';
import { MainRoutes } from '../../routes';
import { Booking, EventDateAndDuration, StepMessage } from '../models';

// TODO : migrate in a lib !!
const getDefaultValues = (): Booking => ({
  bookingForm: undefined,
  bookingData: undefined,
  currentStep: undefined,
  currentStepMessages: undefined,
  isLoading: false,
  availableOptions: undefined,
  availablePrints: undefined,
  possibleDeliveryDates: undefined,
  possibleReturnDates: undefined,
  type: undefined,
  promoCodeIsNotValid: undefined,
  isActive: undefined,
});
@State<Booking>({
  name: 'booking',
  defaults: getDefaultValues(),
})
@Injectable()
export class BookingState {
  constructor(
    private addressService: AddressService,
    private store: Store,
    private translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private bookingService: BookingService,
    private fb: FormBuilder
  ) {}
  private previousBookingFormSubscription$!: Subscription | undefined;

  @Selector()
  public static showPrimaryButton(state: Booking): boolean {
    return (
      (state.currentStep ?? 0) >= 1 && BookingState.currentStepIsValid(state)
    );
  }

  @Selector()
  public static promoCodeIsNotValid(state: Booking): boolean {
    return state.promoCodeIsNotValid ?? false;
  }

  @Selector()
  public static bookingForm(state: Booking): FormGroup | undefined {
    return state.bookingForm;
  }

  @Selector()
  public static showResumeBookingOnRight(state: Booking): boolean {
    return (state.currentStep ?? 0) >= 1;
  }
  @Selector()
  public static availablePrints(state: Booking): PrintDto[] {
    return state.availablePrints ?? [];
  }

  @Selector()
  public static availableOptions(state: Booking): AvailableOptions[] {
    return state.availableOptions ?? [];
  }

  @Selector()
  public static bookingType(state: Booking): BookingType | undefined {
    return state.type;
  }

  @Selector()
  public static deliveryAddressFormGroup(
    state: Booking
  ): AddressFormGroup | undefined {
    return state.bookingForm?.get('deliveryAddress') as AddressFormGroup;
  }

  @Selector()
  public static returnAddressFormGroup(
    state: Booking
  ): AddressFormGroup | undefined {
    return state.bookingForm?.get('returnAddress') as AddressFormGroup;
  }

  @Selector()
  public static invoiceAddressFormGroup(
    state: Booking
  ): AddressFormGroup | undefined {
    return state.bookingForm?.get('invoiceAddress') as AddressFormGroup;
  }

  @Selector()
  public static contactPerson1FormGroup(
    state: Booking
  ): ContactFormGroup | undefined {
    return state.bookingForm?.get('contactPerson1') as ContactFormGroup;
  }

  @Selector()
  public static contactPerson2FormGroup(
    state: Booking
  ): ContactFormGroup | undefined {
    return state.bookingForm?.get('contactPerson2') as ContactFormGroup;
  }

  @Selector()
  public static invoiceContactFormGroup(
    state: Booking
  ): ContactFormGroup | undefined {
    return state.bookingForm?.get('invoiceContact') as ContactFormGroup;
  }
  @Selector()
  public static formHasUniqueContactError(state: Booking): boolean {
    return state.bookingForm?.hasError('UNIQUE_CONTACT') ?? false;
  }

  @Selector()
  public static contactPerson1FormInfo(
    state: Booking
  ): IContactPerson | undefined {
    return BookingState.getContactPersonInfoFromForm(1, state);
  }

  @Selector()
  public static selectedPrint(state: Booking): PrintDto | undefined {
    return state.bookingData?.selectedPrints;
  }
  @Selector()
  public static contactPerson2FormInfo(
    state: Booking
  ): IContactPerson | undefined {
    return BookingState.getContactPersonInfoFromForm(2, state);
  }

  @Selector()
  public static eventDateFormControl(state: Booking): FormControl | undefined {
    return state.bookingForm?.get('eventDate') as FormControl;
  }

  @Selector()
  public static selectedPrintFormControl(
    state: Booking
  ): FormControl | undefined {
    return state.bookingForm?.get('selectedPrint') as FormControl;
  }

  @Selector()
  public static selectedOptionsFormControl(
    state: Booking
  ): FormControl | undefined {
    return state.bookingForm?.get('selectedOptions') as FormControl;
  }

  @Selector()
  public static deliveryAddress(state: Booking): AddressData | undefined {
    return state.bookingData?.deliveryAddress;
  }

  @Selector()
  public static returnAddress(state: Booking): AddressData | undefined {
    return state.bookingData?.returnAddress;
  }

  @Selector()
  public static currentStep(state: Booking): number | undefined {
    return state.currentStep;
  }

  @Selector()
  public static currentStepMessages(state: Booking): StepMessage[] | undefined {
    return state.currentStepMessages;
  }

  @Selector()
  public static showBottomSheet(state: Booking): boolean {
    return !!state?.currentStep;
  }

  @Selector()
  public static bookingTotal(state: Booking): number {
    return calculateBookingPrice(state?.bookingData);
  }

  @Selector()
  public static possibleDeliveryDates(state: Booking): string[] {
    return state.possibleDeliveryDates ?? [];
  }

  @Selector()
  public static possibleReturnDates(state: Booking): string[] {
    return state.possibleReturnDates ?? [];
  }

  @Selector()
  public static primaryBookingButtonText(state: Booking): string {
    switch (state.currentStep) {
      case 1:
        return 'BOOKING.SET_ADDRESS.BUTTON_TEXT';
      case 2:
        return state?.bookingForm?.get('eventDate')?.valid
          ? 'BOOKING.SET_DATE.BUTTON_TEXT'
          : 'BOOKING.SET_ADDRESS.BUTTON_TEXT';
      case 2.5:
        return 'BOOKING.SET_HOURS.BUTTON_TEXT';
      case 3:
        return 'BOOKING.SET_OPTIONS.BUTTON_TEXT';
      case 4:
        return 'BOOKING.SET_CONTACTS.BUTTON_TEXT';
      case 5:
        return 'BOOKING.SET_INVOICE_INFO.BUTTON_TEXT';
      case 6:
        return 'BOOKING.CONFIRMATION.BUTTON_TEXT';
      case 7:
        return 'RESUME_CONTACTS_CARD.CONFIRM_BOOKING';
    }
    return '';
  }

  @Selector()
  public static deliveryAddressAndReturnAddressAreTheSame(
    state: Booking
  ): boolean {
    return addressAreSame(
      state.bookingData?.deliveryAddress,
      state.bookingData?.returnAddress
    );
  }

  @Selector()
  public static bookingOptionsAndPrice(
    state: Booking
  ): Map<string, number> | undefined {
    if (state.bookingData?.options) {
      const toReturn: Map<string, number> = new Map();
      state.bookingData?.options.forEach((value, key) => {
        toReturn.set(key, PRICES[key as keyof typeof PRICES] * value);
      });

      return new Map<string, number>(toReturn);
    }
    return new Map();
  }

  @Selector()
  public static mandatoryOptionsAndPrice(
    state: Booking
  ): Map<string, number> | undefined {
    if (state.bookingData?.options) {
      const mandatory: Map<string, number> = new Map();
      state.bookingData?.options.forEach((value, key) => {
        if (!!MANDATORY_OPTIONS.includes(key as AvailableOptions)) {
          mandatory.set(key, PRICES[key as keyof typeof PRICES] * value);
        }
      });
      return mandatory;
    }
    return new Map();
  }

  @Selector()
  public static optionalOptionsAndPrice(
    state: Booking
  ): Map<string, number> | undefined {
    if (state.bookingData?.options) {
      const optional: Map<string, number> = new Map();
      state.bookingData?.options.forEach((value, key) => {
        if (!MANDATORY_OPTIONS.includes(key as AvailableOptions)) {
          optional.set(key, PRICES[key as keyof typeof PRICES] * value);
        }
      });
      return new Map<string, number>(optional);
    }
    return new Map();
  }

  @Selector()
  public static isLoading(state: Booking): boolean {
    return !!state?.isLoading;
  }

  @Selector()
  public static eventDateAndDuration(
    state: Booking
  ): EventDateAndDuration | undefined {
    return {
      eventDate: state.bookingData?.selectedEventDate,
      duration: state.bookingData?.duration,
    };
  }

  @Selector()
  public static invoiceContactFormInfo(
    state: Booking
  ): IContactPerson | undefined {
    return state?.bookingForm?.get('invoiceContact')?.value as IContactPerson;
  }

  @Selector()
  public static acceptCGVFormControl(state: Booking): FormControl | undefined {
    return state?.bookingForm?.get('acceptCGV') as FormControl;
  }

  @Selector()
  public static acceptNewsLetterFormControl(
    state: Booking
  ): FormControl | undefined {
    return state?.bookingForm?.get('acceptNewsLetter') as FormControl;
  }

  @Selector()
  public static canOpenBottomSheet(state: Booking): boolean {
    return state?.currentStep !== 7;
  }

  @Selector()
  public static showGoToDetailsButton(state: Booking): boolean {
    return state?.currentStep === 7;
  }

  @Selector()
  public static currentStepIsValid(state: Booking): boolean {
    if (state?.isLoading) {
      return false;
    }

    switch (state.currentStep) {
      // Step 1 : Select Address
      case 1:
        return (
          (state.bookingForm?.get('deliveryAddress')?.valid &&
            (!state.bookingForm?.get('returnAddress') ||
              state.bookingForm?.get('returnAddress')?.valid)) ??
          false
        );
      // Step 2 : Select Date
      case 2:
        return (state?.bookingForm?.get('eventDate')?.valid ??
          false) as boolean;
      // Step 3 : Select Options
      case 3:
        return (state?.bookingForm?.get('selectedPrint')?.valid ??
          false) as boolean;
      // Step 4 : Set contacts
      case 4:
        return (
          (state?.bookingForm?.get('contactPerson1')?.valid &&
            state?.bookingForm?.get('contactPerson2')?.valid &&
            !state?.bookingForm?.hasError('UNIQUE_CONTACT')) ??
          false
        );
      // Step 5 : Set invoice info
      case 5:
        return ((state?.bookingForm?.get('invoiceAddress')?.valid &&
          state?.bookingForm?.get('invoiceContact')?.valid) ??
          false) as boolean;
      case 7:
        return state?.bookingForm?.get('acceptCGV')?.value ?? false;
    }
    return true;
  }

  @Action(SetBookingType)
  public setBookingType(
    { patchState, getState }: StateContext<Booking>,
    { data }: SetBookingType
  ): void {
    const currentStartDate =
      getState().bookingForm?.get('eventDate')?.value?.startDate;
    if (currentStartDate) {
      getState().bookingForm?.get('eventDate')?.setValue({
        startDate: currentStartDate,
        endDate: currentStartDate,
      });
    }

    patchState({ type: data });
    this.store.dispatch(new SetMandatoryOptions());
  }

  @Action(SetMandatoryOptions)
  public SetMandatoryOptions({
    patchState,
    getState,
  }: StateContext<Booking>): void {
    const bookingType = getState().type ?? 'b2c';
    const currentOptions = [
      ...(getState().bookingData?.options?.entries() ?? []),
    ]
      // remove mandatory options to readd necessary after
      .filter(([key, value]) => {
        if (MANDATORY_OPTIONS.includes(key as AvailableOptions)) {
          return false;
        }
        return true;
      })
      // remove option from booking type specific
      .filter(filterOptionBasedOnBookingType(bookingType));

    // readd mandatory options for specific booking type
    MANDATORY_OPTIONS.forEach((element) => {
      if (
        (bookingType == 'b2c' &&
          !ONLY_FOR_B2B_OPTIONS.includes(element as AvailableOptions)) ||
        (bookingType == 'b2b' &&
          !ONLY_FOR_B2C_OPTIONS.includes(element as AvailableOptions))
      ) {
        currentOptions.push([element, 1]);
      }
    });
    patchState({
      bookingData: {
        ...getState().bookingData,
        options: new Map<string, number>(currentOptions),
      },
    });
  }

  @Action(SetBookingFormGroup)
  public setBookingFormGroup(
    { patchState }: StateContext<Booking>,
    { data }: SetBookingFormGroup
  ): void {
    if (this.previousBookingFormSubscription$) {
      this.previousBookingFormSubscription$.unsubscribe();
    }
    this.previousBookingFormSubscription$ = data.valueChanges.subscribe(() => {
      patchState({});
    });
    patchState({ bookingForm: data });
  }

  @Action(AddReturnAddressFormGroup)
  public addReturnAddressFormGroup(ctx: StateContext<Booking>) {
    const tmp = ctx.getState().bookingForm;

    tmp?.addControl('returnAddress', new AddressFormGroup());
    ctx.patchState({ bookingForm: tmp });
  }

  @Action(RemoveReturnAddressFormGroup)
  public removeReturnAddressFormGroup(ctx: StateContext<Booking>) {
    const tmp = ctx.getState().bookingForm;

    tmp?.removeControl('returnAddress');
    ctx.patchState({ bookingForm: tmp });
  }

  @Action(ClickOnPrimaryButton)
  public clickOnPrimaryButton(ctx: StateContext<Booking>): void {
    if (BookingState.currentStepIsValid(ctx.getState())) {
      switch (ctx.getState().currentStep) {
        case 1:
          ctx.dispatch(new VerifyAddress());
          break;
        case 2:
          ctx.dispatch(new VerifyDate());
          break;
        case 2.5:
        case 3:
        case 4:
        case 5:
        case 6:
          ctx.dispatch(new GoNextStep());
          break;
      }
    }
  }

  @Action(ClearData)
  public clearData({ setState }: StateContext<Booking>): void {
    if (this.previousBookingFormSubscription$) {
      this.previousBookingFormSubscription$.unsubscribe();
      this.previousBookingFormSubscription$ = undefined;
    }
    setState(getDefaultValues());
  }

  // TODO : use select when state migrated in lib
  @Action(GetIsActive)
  public getIsActive({ getState }: StateContext<Booking>): boolean {
    return !!getState().isActive;
  }

  @Action(PrepareBookingForm)
  public prepareBookingForm({ bookingType }: PrepareBookingForm): void {
    const bookingForm = this.fb.group({
      deliveryAddress: new AddressFormGroup(),
      eventDate: [
        null,
        [Validators.required, minRangeDateValidator(getMinimumDate())],
      ],
      selectedPrint: [null, Validators.required],
      selectedOptions: [],
      contactPerson1: new ContactFormGroup(),
      contactPerson2: new ContactFormGroup(),
      invoiceAddress: new AddressFormGroup(),
      invoiceContact: new ContactFormGroup(),
      acceptCGV: [false, Validators.requiredTrue],
      acceptNewsLetter: [false],
      deliveryDate: [null, []],
      returnDate: [null, []],
      deliveryHour: [null, []],
      returnHour: [null, []],
    });
    bookingForm.addValidators([
      uniqueContactValidator(['contactPerson1', 'contactPerson2']),
    ]);

    this.store.dispatch([
      new InitBooking(bookingType),
      new SetBookingFormGroup(bookingForm),
    ]);
  }

  @Action(SetBookingStep)
  public setBookingStep(
    ctx: StateContext<Booking>,
    { data }: SetBookingStep
  ): void {
    if (this.previousBookingFormSubscription$) {
      this.previousBookingFormSubscription$.unsubscribe();
      this.previousBookingFormSubscription$ = undefined;
    }
    ctx.patchState({ currentStep: data });
    this.previousBookingFormSubscription$ = ctx
      .getState()
      .bookingForm?.valueChanges.subscribe(() => {
        ctx.patchState({});
      });
  }

  @Action(GoNextStep)
  public goNextStep(ctx: StateContext<Booking>): void {
    switch (ctx.getState().currentStep) {
      case 1:
        this.saveAddressInState(ctx);
        this.store.dispatch(
          new Navigate([`${MainRoutes.BOOKING}/${BookingRoutes.SET_DATE}`])
        );
        break;
      case 2:
        this.saveEventDateInState(ctx);
        if (ctx.getState()?.type === 'b2c') {
          this.store.dispatch(
            new Navigate([`${MainRoutes.BOOKING}/${BookingRoutes.SET_OPTIONS}`])
          );
        } else {
          this.store.dispatch(
            new Navigate([`${MainRoutes.BOOKING}/${BookingRoutes.SET_HOURS}`])
          );
        }

        break;
      case 2.5:
        this.store.dispatch(
          new Navigate([`${MainRoutes.BOOKING}/${BookingRoutes.SET_OPTIONS}`])
        );
        break;
      case 3:
        this.store.dispatch(
          new Navigate([`${MainRoutes.BOOKING}/${BookingRoutes.SET_CONTACTS}`])
        );
        break;
      case 4:
        this.store.dispatch(
          new Navigate([`${MainRoutes.BOOKING}/${BookingRoutes.SET_INVOICE}`])
        );
        break;
      case 5:
        this.store.dispatch(
          new Navigate([`${MainRoutes.BOOKING}/${BookingRoutes.CONFIRMATION}`])
        );
        break;
      case 6:
        this.store.dispatch(
          new Navigate([`${MainRoutes.BOOKING}/${BookingRoutes.DETAILS}`])
        );
        break;
    }
  }

  @Action(InitBooking)
  public initBooking(
    { patchState }: StateContext<Booking>,
    { bookingType }: InitBooking
  ): void {
    patchState({
      bookingData: initNewBooking(bookingType ?? 'b2c'),
      availableOptions: Object.keys(AvailableOptions)
        .filter((_) => !NOT_SELECTABLE_OPTIONS.includes(_ as AvailableOptions))
        .map((key) => AvailableOptions[key as keyof typeof AvailableOptions]),
      availablePrints: [
        {
          id: 1,
          name: `Pas d'impression`,
          price: 0,
          quantity: 0,
        },
        {
          id: 2,
          name: `100 impressions`,
          price: 101,
          quantity: 100,
        },
        {
          id: 3,
          name: `200 impressions`,
          price: 151,
          quantity: 200,
        },
        {
          id: 4,
          name: `400 impressions`,
          price: 201,
          quantity: 400,
        },
      ], // TODO : take info from api
    });
  }

  @Action(VerifyDate)
  public verifyDate(ctx: StateContext<Booking>): void {
    const currentState = ctx.getState();
    const eventDate = currentState.bookingForm?.get('eventDate')?.value as
      | DateRange
      | undefined;
    if (eventDate?.startDate) {
      this.store.dispatch(new SetIsLoading(true));

      const duration =
        compareDate(eventDate?.startDate, eventDate?.endDate) + 1;
      this.bookingService
        .verifyDate({
          eventDate: eventDate?.startDate,
          numberOfDays: duration,
          address: this.addressFormToVerifyAddressDto(
            currentState.bookingForm?.get('deliveryAddress') as AddressFormGroup
          ),
        })
        .pipe(
          tap((data) => {
            this.store.dispatch(new SetIsLoading(false));

            if (data?.isAvailable) {
              const currentBooking = ctx.getState().bookingData;
              if (currentBooking) {
                currentBooking.selectedEventDate = eventDate.startDate;
                currentBooking.infoForUser = {
                  ...currentBooking.infoForUser,
                  deliveryDate: data.deliveryDate,
                  returnDate: data.returnDate,
                };
              }

              ctx.patchState({
                bookingData: currentBooking,
              });
              if (currentState.type === 'b2b') {
                this.store.dispatch([
                  new SetPossibleDeliveryDates(
                    getPossibleDeliveryDateForB2BEventDate(
                      eventDate.startDate
                    ).map((_) => formatDate(_))
                  ),
                  new SetPossibleReturnDates(
                    getPossibleReturnDateForB2BEventDate(eventDate.endDate).map(
                      (_) => formatDate(_)
                    )
                  ),
                ]);
              }
              this.store.dispatch(new SetDuration(duration));
              this.store.dispatch(new GoNextStep());
            }
          })
        )
        .subscribe();
    }
  }

  @Action(SetPrints)
  public setPrints(
    ctx: StateContext<Booking>,
    { selectedPrint }: SetPrints
  ): void {
    const tmp = ctx.getState().bookingData;
    if (tmp) {
      tmp.selectedPrints = selectedPrint;
      ctx.patchState({ bookingData: tmp });
    }
  }

  @Action(SetOptions)
  public setOptions(ctx: StateContext<Booking>, { data }: SetOptions): void {
    const tmp = ctx.getState().bookingData;
    if (tmp) {
      data.forEach((option) => {
        if (!option.quantity) {
          tmp.options?.delete(option.option);
        } else {
          tmp.options?.set(option.option, option.quantity);
        }
      });
      ctx.patchState({ bookingData: tmp });
    }
  }

  @Action(SetIsLoading)
  public setIsLoading(
    ctx: StateContext<Booking>,
    { isLoading }: SetIsLoading
  ): void {
    ctx.patchState({ isLoading });
    if (isLoading) {
      ctx.getState().bookingForm?.disable();
    } else {
      ctx.getState().bookingForm?.enable();
    }
  }

  @Action(SetPossibleDeliveryDates)
  public SetPossibleDeliveryDates(
    ctx: StateContext<Booking>,
    { dates }: SetPossibleDeliveryDates
  ): void {
    ctx.patchState({ possibleDeliveryDates: dates ?? [] });
  }

  @Action(SetPossibleReturnDates)
  public SetPossibleReturnDates(
    ctx: StateContext<Booking>,
    { dates }: SetPossibleReturnDates
  ): void {
    ctx.patchState({ possibleReturnDates: dates ?? [] });
  }

  @Action(SetDuration)
  public SetDuration(ctx: StateContext<Booking>, { duration }: SetDuration) {
    ctx.patchState({
      bookingData: { ...ctx.getState().bookingData, duration },
    });
    this.store.dispatch(
      new SetOptions([
        {
          option:
            ctx.getState().type === 'b2c'
              ? AvailableOptions.b2cDay
              : AvailableOptions.b2bDay,
          quantity: duration,
        },
      ])
    );
  }

  @Action(CheckPromoCode)
  public checkPromoCode(
    { patchState }: StateContext<Booking>,
    { code }: CheckPromoCode
  ): void {
    this.bookingService
      .checkPromoCode(code)
      .pipe()
      .subscribe((data) => {
        // TODO: apply or not promo code
        patchState({ promoCodeIsNotValid: false });
      });
  }

  @Action(VerifyAddress)
  public verifyAddress(ctx: StateContext<Booking>): void {
    const deliveryAddress = ctx
      .getState()
      .bookingForm?.get('deliveryAddress') as AddressFormGroup;

    if (deliveryAddress) {
      this.store.dispatch(new SetIsLoading(true));
      this.addressService
        .verify(this.addressFormToVerifyAddressDto(deliveryAddress))
        .pipe(
          take(1),
          tap((data) => {
            this.store.dispatch(new SetIsLoading(false));

            if (data.isValid) {
              this.store.dispatch([
                new GoNextStep(),
                new SetOptions([
                  {
                    option: AvailableOptions.b2cDelivery,
                    quantity: data.isFreeDelivery ? 0 : 1,
                  },
                ]),
              ]);
              ctx.patchState({
                currentStepMessages: [],
              });
            } else {
              ctx.getState().bookingForm?.get('deliveryAddress')?.setErrors({
                notDeliverable: true,
              });
              ctx.patchState({
                currentStepMessages: [
                  {
                    message: this.translateService.instant(
                      'BOOKING.ADDRESS_NOT_DELIVERABLE'
                    ),
                    type: 'error',
                  },
                ],
              });
            }
          }),
          catchError(() => {
            this.store.dispatch(new SetIsLoading(false));
            return of(false);
          })
        )
        .subscribe();
    }
  }

  private addressFormToVerifyAddressDto(
    deliveryAddress: AddressFormGroup
  ): IVerifyAddressRequest {
    const rules = getAddressRules();

    return {
      city: deliveryAddress.get(rules.city.fieldName)?.value,
      streetName: deliveryAddress.get(rules.streetName.fieldName)?.value,
      streetNumber: deliveryAddress.get(rules.streetNumber.fieldName)?.value,
      postalCode: deliveryAddress.get(rules.postalCode.fieldName)?.value,
      country: deliveryAddress.get(rules.country.fieldName)?.value,
    };
  }

  private saveEventDateInState(ctx: StateContext<Booking>) {
    const currentBooking =
      ctx.getState().bookingData ||
      initNewBooking(ctx.getState().type ?? 'b2c');

    const dateFromCalendar = ctx.getState().bookingForm?.get('eventDate')
      ?.value as DateRange;

    currentBooking.selectedEventDate = dateFromCalendar?.startDate;
    currentBooking.duration =
      compareDate(dateFromCalendar.startDate, dateFromCalendar.endDate) + 1;

    ctx.patchState({
      bookingData: currentBooking,
    });
  }
  private saveAddressInState(ctx: StateContext<Booking>) {
    const currentBooking =
      ctx.getState().bookingData ||
      initNewBooking(ctx.getState().type ?? 'b2c');
    const deliveryAddress = ctx
      .getState()
      .bookingForm?.get('deliveryAddress') as AddressFormGroup;
    const deliveryAddressToSet: AddressData =
      addressFormGroupToAddressData(deliveryAddress);
    const returnAddress = ctx
      .getState()
      .bookingForm?.get('returnAddress') as AddressFormGroup;
    const returnAddressToSet: AddressData = returnAddress
      ? addressFormGroupToAddressData(returnAddress)
      : deliveryAddressToSet;
    ctx.patchState({
      bookingData: {
        ...currentBooking,
        deliveryAddress: deliveryAddressToSet,
        returnAddress: returnAddressToSet,
      },
    });
  }

  private static getContactPersonInfoFromForm(
    contactNumber: number,
    state: Booking
  ): IContactPerson | undefined {
    const contactPerson = state.bookingForm?.get(
      `contactPerson${contactNumber}`
    ) as ContactFormGroup;
    if (contactPerson) {
      return {
        names: contactPerson.get(NAMES.FIELD_NAME)?.value,
        phone: contactPerson.get(PHONE.FIELD_NAME)?.value,
        email: contactPerson.get(EMAIL.FIELD_NAME)?.value,
      };
    }
    return undefined;
  }
}
function newFunction(
  bookingType: string
): (
  value: [string, number],
  index: number,
  array: [string, number][]
) => unknown {
  return ([key, value]) => {
    if (MANDATORY_OPTIONS.includes(key as AvailableOptions)) {
      return false;
    }
    if (
      bookingType == 'b2c' &&
      ONLY_FOR_B2B_OPTIONS.includes(key as AvailableOptions)
    ) {
      return false;
    }

    if (
      bookingType == 'b2b' &&
      ONLY_FOR_B2C_OPTIONS.includes(key as AvailableOptions)
    ) {
      return false;
    }

    return true;
  };
}
