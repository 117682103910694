import { Route } from '@angular/router';
import { AuthCustomerGuard } from '@burddy-monorepo/front/shared/guards';

import { headerResolver } from '../../../route-resolvers';
import { BookingRoutes } from '../booking-routes.enum';

export const bookingRoutes: Route[] = [
  {
    path: BookingRoutes.SELECT_PRO_OR_PRIVATE,
    loadComponent: () =>
      import('./select-private-or-pro/select-private-or-pro.component').then(
        (m) => m.SelectPrivateOrProComponent
      ),
    resolve: { isHeaderResolve: headerResolver },
  },
  {
    path: BookingRoutes.SET_ADDRESS,
    loadComponent: () =>
      import('./set-address/set-address.component').then(
        (m) => m.SetAddressComponent
      ),
    resolve: { isHeaderResolve: headerResolver },
  },
  {
    path: BookingRoutes.SET_DATE,
    loadComponent: () =>
      import('./set-date/set-date.component').then((m) => m.SetDateComponent),
    resolve: { isHeaderResolve: headerResolver },
  },
  {
    path: BookingRoutes.SET_OPTIONS,
    loadComponent: () =>
      import('./set-options/set-options.component').then(
        (m) => m.SetOptionsComponent
      ),
    resolve: { isHeaderResolve: headerResolver },
  },
  {
    path: BookingRoutes.LOG_CUSTOMER,
    data: {
      showedInBookingFlow: true,
    },
    loadComponent: () =>
      import('./booking-login/booking-login.component').then(
        (m) => m.BookingLoginComponent
      ),
    resolve: { isHeaderResolve: headerResolver },
  },
  {
    path: BookingRoutes.SET_CONTACTS,
    canActivate: [AuthCustomerGuard],
    loadComponent: () =>
      import('./set-contacts/set-contacts.component').then(
        (m) => m.SetContactsComponent
      ),
    resolve: { isHeaderResolve: headerResolver },
  },
  {
    path: BookingRoutes.SET_INVOICE,
    canActivate: [AuthCustomerGuard],
    loadComponent: () =>
      import('./set-invoice/set-invoice.component').then(
        (m) => m.SetInvoiceComponent
      ),
    resolve: { isHeaderResolve: headerResolver },
  },
  {
    path: BookingRoutes.CONFIRMATION,
    canActivate: [AuthCustomerGuard],
    loadComponent: () =>
      import('./confirmation-page/confirmation-page.component').then(
        (m) => m.ConfirmationPageComponent
      ),
    resolve: { isHeaderResolve: headerResolver },
  },
  {
    path: BookingRoutes.DETAILS,
    canActivate: [AuthCustomerGuard],
    loadComponent: () =>
      import('./booking-details/booking-details.component').then(
        (m) => m.BookingDetailsComponent
      ),
    resolve: { isHeaderResolve: headerResolver },
  },
  {
    path: BookingRoutes.SET_HOURS,
    loadComponent: () =>
      import('./set-hours/set-hours.component').then(
        (m) => m.SetHoursComponent
      ),
    resolve: { isHeaderResolve: headerResolver },
  },
  {
    path: '**',
    redirectTo: BookingRoutes.SELECT_PRO_OR_PRIVATE,
    pathMatch: 'full',
  },
];
