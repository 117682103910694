import { FormControl, FormGroup } from '@angular/forms';
import { getContactPersonRules } from '@burddy-monorepo/shared/shared-data';

import { getValidatorsBasedOnRules } from '../../../../../validators/src/lib/utils';

export interface ContactFormGroupInitValues {
  names: string;
  email: string;
  phone: string;
}
export class ContactFormGroup extends FormGroup {
  constructor(init?: ContactFormGroupInitValues) {
    const rules = getContactPersonRules();
    super({
      [rules.names.fieldName]: new FormControl(
        init?.names,
        getValidatorsBasedOnRules(rules.names)
      ),
      [rules.email.fieldName]: new FormControl(
        init?.email,
        getValidatorsBasedOnRules(rules.email)
      ),
      [rules.phone.fieldName]: new FormControl(
        init?.phone,
        getValidatorsBasedOnRules(rules.phone)
      ),
    });
  }
}
