<burddy-monorepo-header *ngIf="data?.isShown">
  <div class="-row" left *ngIf="!!data?.leftSection">
    <burddy-monorepo-button
      (clickEvent)="askQuitBooking()"
      *ngIf="data?.leftSection?.showCloseButton"
      class="close border tertiary"
    ></burddy-monorepo-button>
    <a routerLink="/" *ngIf="data?.leftSection?.showFullBurddyLogo">
      <img src="/assets/img/logo/logo-with-text.svg" alt="Burddy" />
    </a>
    <h3 *ngIf="data?.leftSection?.title">
      {{ data.leftSection?.title ?? '' | translate }}
    </h3>
  </div>
  <div class="-row" right *ngIf="!!data?.rightSection">
    <burddy-monorepo-button
      [class]="'primary'"
      *ngIf="data?.rightSection?.showBookingButton"
    >
      <span left
        ><img src="/assets/img/icons/calendar.svg" alt="calendar" />
      </span>
      {{ 'HEADER.BOOK_BUTTON' | translate }}</burddy-monorepo-button
    >
    <img
      *ngIf="data?.rightSection?.showBurddyLogo"
      src="/assets/img/logo/logo_only.svg"
      alt="Burddy"
    />

    <img
      *ngIf="data?.rightSection?.showMenuBurger"
      class="clickable"
      src="/assets/img/icons/menu.svg"
      alt="calendar"
    />
  </div>
</burddy-monorepo-header>

<ng-template #leavingAlert>
  <burddy-monorepo-card>
    <div
      class="-column center-content -padding24 -space-between -full-height -border-box"
    >
      <burddy-monorepo-badge class="error small">
        <img src="/assets/img/icons/attention.svg" alt="error" />
      </burddy-monorepo-badge>
      <div class="center-text">
        <h3>{{ 'BOOKING.LEAVING_ALERT.TITLE' | translate }}</h3>
        <p>{{ 'BOOKING.LEAVING_ALERT.EXPLANATION' | translate }}</p>
      </div>
      <div>
        <burddy-monorepo-button
          [class]="'secondary -margin8'"
          (clickEvent)="quitBooking()"
          >{{
            'BOOKING.LEAVING_ALERT.PRIMARY_BUTTON' | translate
          }}</burddy-monorepo-button
        >
        <burddy-monorepo-button
          [class]="'tertiary -margin8'"
          (clickEvent)="leaveAlert()"
          >{{
            'BOOKING.LEAVING_ALERT.SECONDARY_BUTTON' | translate
          }}</burddy-monorepo-button
        >
      </div>
    </div>
  </burddy-monorepo-card>
</ng-template>
