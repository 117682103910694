import { FormControl, FormGroup } from '@angular/forms';
import { getAddressRules } from '@burddy-monorepo/shared/shared-data';

import { getValidatorsBasedOnRules } from '../../../../../validators/src/lib/utils';

export interface AddressFormGroupInitValues {
  streetNumber: string;
  postalCode: string;
  city: string;
  streetName: string;
  country: string;
  complement: string;
}
export class AddressFormGroup extends FormGroup {
  constructor(init?: AddressFormGroupInitValues) {
    const rules = getAddressRules();
    super({
      [rules.streetNumber.fieldName]: new FormControl(
        init?.streetNumber,
        getValidatorsBasedOnRules(rules.streetNumber)
      ),
      [rules.streetName.fieldName]: new FormControl(
        init?.streetName,
        getValidatorsBasedOnRules(rules.streetName)
      ),
      [rules.postalCode.fieldName]: new FormControl(
        init?.postalCode,
        getValidatorsBasedOnRules(rules.postalCode)
      ),
      [rules.city.fieldName]: new FormControl(
        init?.city,
        getValidatorsBasedOnRules(rules.city)
      ),
      [rules.country.fieldName]: new FormControl(
        init?.country,
        getValidatorsBasedOnRules(rules.country)
      ),
      [rules.complement.fieldName]: new FormControl(
        init?.complement,
        getValidatorsBasedOnRules(rules.complement)
      ),
    });
  }
}
