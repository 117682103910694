import { NgClass, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Input,
} from '@angular/core';

/**
 *
 *
 * @export
 * @class BottomSheetComponent
 */
@Component({
  selector: 'burddy-monorepo-bottom-sheet',
  standalone: true,
  imports: [NgIf, NgClass],
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BottomSheetComponent {
  startY!: number;
  currentPosition!: number;
  @Input() isOpenable?: boolean;
  @HostBinding('class.-open') opened = false;

  @HostListener('touchstart', ['$event'])
  onTouchStart(event: TouchEvent) {
    if (!this.isOpenable) return;
    this.startY = event.touches[0].clientY;
    this.currentPosition = this.startY;
  }

  @HostListener('touchmove', ['$event'])
  onTouchMove(event: TouchEvent) {
    if (!this.isOpenable) return;
    this.currentPosition = event.touches[0].clientY;
    const deltaY = this.currentPosition - this.startY;

    if (deltaY > 0) {
      // L'utilisateur fait glisser vers le bas
      // Vous pouvez mettre en place votre logique ici pour afficher le bottomSheet complètement
      this.opened = false;
    } else {
      // L'utilisateur fait glisser vers le haut
      // Vous pouvez mettre en place votre logique ici pour masquer le bottomSheet
      this.opened = true;
    }
  }
}
