import { Route } from '@angular/router';

import { CustomizeBookingRoutes } from '../customize-booking-routes.enum';

export const customizeRoutes: Route[] = [
  {
    path: CustomizeBookingRoutes.MAIN_INFO,
    loadComponent: () =>
      import('./customize-main-info/customize-main-info.component').then(
        (m) => m.CustomizeMainInfoComponent
      ),
  },
  {
    path: '**',
    redirectTo: CustomizeBookingRoutes.MAIN_INFO,
    pathMatch: 'full',
  },
];
