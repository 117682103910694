export enum APIPathCustomers {
  MAIN = 'customers',
  LOG = 'login',
}

export enum APIPathBooking {
  MAIN = 'bookings',
  MY_BOOKINGS = 'my-bookings',
  CHECK_PROMO_CODE = 'check-promo-code',
}
