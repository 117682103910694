<ng-container [formGroup]="formGroup">
  <ng-container *ngIf="formGroup">
    <div class="-form-line">
      <div class="-form-item">
        <label for="streetNumber">{{
          'FORM.ADDRESS.NUMBER' | translate
        }}</label>
        <input
          type="text"
          name="streetNumber"
          [maxlength]="+(addressRules.streetNumber.maxLength ?? 99999)"
          [minlength]="+(addressRules.streetNumber.minLength ?? 0)"
          [formControlName]="addressRules.streetNumber.fieldName"
          [placeholder]="'FORM.ADDRESS.NUMBER_PLACEHOLDER' | translate"
        />
      </div>
      <div class="-form-item">
        <label for="street">{{ 'FORM.ADDRESS.STREET' | translate }}</label>
        <input
          type="text"
          name="streetName"
          [maxlength]="+(addressRules.streetName?.maxLength ?? 99999)"
          [minlength]="+(addressRules.streetName?.minLength ?? 0)"
          [formControlName]="addressRules.streetName.fieldName"
          [placeholder]="'FORM.ADDRESS.STREET_PLACEHOLDER' | translate"
        />
      </div>
    </div>

    <div class="-form-line">
      <div class="-form-item">
        <label for="postalCode">{{ 'FORM.ADDRESS.ZIPCODE' | translate }}</label>
        <input
          type="text"
          name="postalCode"
          [maxlength]="+(addressRules.postalCode.maxLength ?? 99999)"
          [minlength]="+(addressRules.postalCode.minLength ?? 0)"
          [formControlName]="addressRules.postalCode.fieldName"
          [placeholder]="'FORM.ADDRESS.ZIPCODE_PLACEHOLDER' | translate"
        />
      </div>
      <div class="-form-item">
        <label for="city">{{ 'FORM.ADDRESS.CITY' | translate }}</label>
        <input
          type="text"
          name="city"
          [maxlength]="+(addressRules.city?.maxLength ?? 99999)"
          [minlength]="+(addressRules.city?.minLength ?? 0)"
          [formControlName]="addressRules.city.fieldName"
          [placeholder]="'FORM.ADDRESS.CITY_PLACEHOLDER' | translate"
        />
      </div>
    </div>

    <div class="-form-line">
      <div class="-form-item">
        <label for="country">{{ 'FORM.ADDRESS.COUNTRY' | translate }}</label>
        <select
          [formControlName]="addressRules.country.fieldName"
          name="country"
        >
          <option value="" disabled selected>
            {{ 'FORM.ADDRESS.COUNTRY_PLACEHOLDER' | translate }}
          </option>
          <option [value]="country" *ngFor="let country of availableCountries">
            {{ 'SHARED.COUNTRIES.' + country | translate }}
          </option>
        </select>
      </div>
    </div>

    <div class="-form-line" *ngIf="showComplement">
      <div class="-form-item">
        <label for="complement"
          >{{ 'FORM.ADDRESS.COMPLEMENT' | translate }}
          <span class="light-grey1">{{
            'FORM.FACULTATIVE' | translate
          }}</span></label
        >
        <textarea
          name="complement"
          [maxlength]="+(addressRules.complement.maxLength ?? 99999)"
          [minlength]="+(addressRules.complement.minLength ?? 0)"
          [formControlName]="addressRules.complement.fieldName"
          [placeholder]="'FORM.ADDRESS.COMPLEMENT_PLACEHOLDER' | translate"
        ></textarea>
      </div>
    </div>
  </ng-container>
</ng-container>
