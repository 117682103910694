import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { importProvidersFrom, LOCALE_ID } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter, withEnabledBlockingInitialNavigation } from '@angular/router';
import { BookingCustomizationState, CustomerState, LoginState } from '@burddy-monorepo/front/shared/data';
import { HeaderInterceptor } from '@burddy-monorepo/front/shared/interceptors';
import { ENVIRONNEMENT } from '@burddy-monorepo/front/shared/interfaces';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';

import { AppComponent } from './app/app.component';
import { appRoutes } from './app/app.routes';
import { BookingState, HeaderState } from './data/states';
import { environment } from './environments/environment';

registerLocaleData(localeFr);
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/translations/', '.json');
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(HttpClientModule),
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },

    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      })
    ),
    importProvidersFrom(
      NgxsModule.forRoot([
        HeaderState,
        BookingState,
        LoginState,
        CustomerState,
        BookingCustomizationState,
      ])
    ),
    importProvidersFrom(NgxsRouterPluginModule.forRoot()),
    importProvidersFrom(BrowserAnimationsModule),
    provideRouter(appRoutes, withEnabledBlockingInitialNavigation()),
    {
      provide: ENVIRONNEMENT,
      useValue: environment,
    },
    {
      provide: LOCALE_ID,
      useValue: 'fr-FR',
    },
  ],
}).catch((err) => console.error(err));
