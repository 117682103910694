import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CustomerState } from '@burddy-monorepo/front/shared/data';
import { Store } from '@ngxs/store';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthCustomerGuard implements CanActivate {
  private store = inject(Store);
  private router = inject(Router);
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store.select(CustomerState.hasConnectedUser).pipe(
      map((connected) => {
        if (connected) {
          return true;
        } else {
          return this.router.createUrlTree(['booking/login']); // TODO : use enum for route when migrated in lib
        }
      })
    );
  }
}
